import * as React from 'react';
import { TableCell, Typography } from '@mui/material';
import { NotSpecified } from 'utils/constants';
import { PatientPersonalityTestResultTextProps } from 'pages/Dashboard/types/whoiam.types';


export default function GenericTestText({
  result,
  isSummary = false,
}: PatientPersonalityTestResultTextProps): JSX.Element {
  const locusOfControlResult = result ? `${result.score}  out of ${result.totalScore}` : NotSpecified;
  const Container = isSummary ? TableCell : React.Fragment;
  return (
    <Container>
      <Typography padding={1.25}>{locusOfControlResult}</Typography>
    </Container>
  );
}
