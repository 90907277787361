import * as React from 'react';
import { TableCell, Typography } from '@mui/material';
import { NotSpecified } from 'utils/constants';
import { PatientPersonalityTestResultTextProps } from 'pages/Dashboard/types/whoiam.types';


export default function CoreValuesText({
  result,
  isSummary = false,
}: PatientPersonalityTestResultTextProps): JSX.Element {
  const Container = isSummary ? TableCell : React.Fragment;
  return (
    <Container>
      <Typography padding={1.25}>{result?.description || NotSpecified}</Typography>
    </Container>
  );
}
