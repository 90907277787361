import { Box, capitalize, Skeleton, Typography } from '@mui/material';
import AppleHealthIcon from 'components/Common/AppleHealthIcon';
import AppleHealthActivitySummary from 'pages/Dashboard/components/AppleHealthActivitySummary';
import AppleHealthSleepChartSummary from 'pages/Dashboard/components/AppleHealthSleepChartSummary';
import SleepChart from 'pages/Dashboard/components/SelfReportedSleepChart';
import {
  ListWaypointDetails,
  WaypointTrackingAPIResponse,
} from 'pages/Dashboard/types/waypoints.types';
import usePatientTrackingSummary from 'pages/Dashboard/usePatientTrackingSummary';
import { TimeFrameOptions, defaultWaypointTracking } from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import Theme from 'theme';
import { MixpanelEventName } from 'utils/constants';
import { trackMixpanelEvent } from 'utils/utilMethods';

interface PatientTrackingProps {
  patientId: string;
  isBehavioralDataPresent: boolean;
}

export default function PatientTrackingSummary({
  patientId,
  isBehavioralDataPresent,
}: PatientTrackingProps) {
  const timeFrame = TimeFrameOptions.LAST_30_DAYS;
  const {
    widget,
    fetchActivityPatientWaypointTrackingResult: fetchActivityResult,
    fetchSleepPatientWaypointTrackingResult: fetchSleepResult,
    fetchCurrentSelfReportWaypointTrackingResult: fetchCurrent,
    fetchPreviousSelfReportWaypointTrackingResult: fetchPrevious,
    setSourceId,
    sourceId,
    sourceOptions,
    isLoading,
    sourceIdActivity,
    sourceOptionsActivity,
    setSourceIdActivity,
    sourceIdPlatformActivity,
    sourceIdPlatformSleep,
  } = usePatientTrackingSummary(patientId);
  const noDataMessage = isBehavioralDataPresent ? 'No data for this timeframe' : 'No data currently exists';

  const onChangeSource = React.useCallback(
    (source: string) => {
      trackMixpanelEvent(
        MixpanelEventName.AH_SLEEP_CHART_SOURCE_CHANGED,
        {
          'patient id': patientId ?? '',
        },
        true,
      );
      setSourceId(source);
    },
    [patientId, setSourceId],
  );
  const onChangeSourceActivity = React.useCallback(
    (source: string) => {
      trackMixpanelEvent(
        MixpanelEventName.AH_SLEEP_CHART_SOURCE_CHANGED,
        {
          'patient id': patientId ?? '',
        },
        true,
      );
      setSourceIdActivity(source);
    },
    [patientId, setSourceIdActivity],
  );

  const currentData = (fetchCurrent.data as WaypointTrackingAPIResponse) ?? defaultWaypointTracking;
  const previousDa = (fetchPrevious.data as WaypointTrackingAPIResponse) ?? defaultWaypointTracking;

  const sleepData = fetchSleepResult.data as ListWaypointDetails;
  const currentSleepData = sleepData?.categoryAvgData;
  const previousSleepData = sleepData?.previousIntervalCategoryAvgData;
  const activityData = fetchActivityResult.data as WaypointTrackingAPIResponse;
  const currentActivityData = activityData?.averages;
  const previousActivityData = activityData?.previousIntervalAverages;
  if (isLoading) {
    return (
      <Skeleton
        height='340px'
        width='100%'
        sx={{
          transform: 'scale(1, 1)',
        }}
      />
    );
  }
  return (
    <Box width='100%' mr={0} display='flex'>
      {widget === 'sleep' && (
        <Box width='100%' mt={2}>
          <Box display='flex' alignItems='center' mb={2}>
            <Typography fontSize={16} mr='12px'>
              Sleep
            </Typography>
            <AppleHealthIcon sourceObject={{ sourcePlatform: sourceIdPlatformSleep }} />
          </Box>
          <AppleHealthSleepChartSummary
            timeFrame={timeFrame}
            averages={currentSleepData}
            previousPeriodAverages={previousSleepData}
            setSourceId={onChangeSource}
            sourceId={sourceId}
            sourceOptions={sourceOptions}
          />
        </Box>
      )}
      {widget === 'activity' && (
        <Box display='flex' flexDirection='column' mt={2}>
          <Box display='flex' alignItems='center' mb={2}>
            <Typography fontSize={16} mr={1}>
              {' '}
              Activity
            </Typography>
            <AppleHealthIcon sourceObject={{ sourcePlatform: sourceIdPlatformActivity }} />
          </Box>
          <AppleHealthActivitySummary
            averages={currentActivityData}
            previousPeriodAverages={previousActivityData}
            timeFrame={timeFrame}
            sourceId={sourceIdActivity}
            sourceOptions={sourceOptionsActivity}
            setSourceId={onChangeSourceActivity}
          />
        </Box>
      )}
      {widget === 'selfReport' && (
        <Box display='flex' flexDirection='column' mt={2}>
          <Box display='flex' alignItems='center' mb={2}>
            <Typography fontSize={16}>
              {capitalize(currentData?.waypoints?.[0]?.waypoint?.name ?? '')}
            </Typography>
          </Box>
          <SleepChart
            currentDataArray={currentData?.waypoints}
            previousDataArray={previousDa?.waypoints}
          />
        </Box>
      )}
      {widget === 'NO_DATA' && (
        <Box
          width='100%'
          height='340px'
        >
          <Typography
            color={Theme.custom.colors.lightTextSecondary}
            mt={2}
          >
            {noDataMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
