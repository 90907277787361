import { Grid } from '@mui/material';
import AppleHealthCommonChart from 'pages/Dashboard/components/AppleHealthCommonChart';
import { TimeFrameOptions } from 'pages/Dashboard/utils/trackingUtils';
import React, { useEffect } from 'react';

type Waypoint = {
    id: string;
    title?: string;
    subTitle?: string;
  };
interface HealthWaypointChartsSectionProps {
   appleHealthWaypointMap:{
    [key:string]:string
   };
    providerId:string;
    timeFrame:TimeFrameOptions;
    waypointsList: Waypoint[];
    patientId:string
    openDrawer?:()=>void
    onChangeTimeFrame: (v: string) => void;
}

export default function HealthWaypointChartsSection({
  waypointsList,
  appleHealthWaypointMap,
  timeFrame,
  providerId,
  patientId,
  openDrawer,
  onChangeTimeFrame }:
    HealthWaypointChartsSectionProps) {
  const keyArray = Object.keys(appleHealthWaypointMap);
  const reverseAppleHealthWaypointMap = keyArray.reduce((acc, key) => {
    acc[appleHealthWaypointMap[key]] = key;
    return acc;
  }, {} as { [key: string]: string });
  const [numberOfCards, setNumberOfCards] = React.useState(0);
  const [cardDataStatus, setCardDataStatus] = React.useState({
    sleepDerived: false,
    activityDerived: false,
    stepCountDerived: false,
    daylightExposure: false,
  });
  const chartOrder = ['sleepDerived', 'activityDerived', 'stepCountDerived', 'daylightExposure'];
  const updateCardDataStatus = (type: string, data:boolean) => {
    setCardDataStatus((prev) => ({ ...prev, [type]: data }));
  };
  const waypointsListSorted = waypointsList
    ?.sort((a, b) => chartOrder
      .indexOf(reverseAppleHealthWaypointMap[a.id])
      - chartOrder.indexOf(reverseAppleHealthWaypointMap[b.id]));
  useEffect(() => {
    const number = Object.values(cardDataStatus).filter((v) => v).length;
    setNumberOfCards(number);
  }, [cardDataStatus]);
  return (
    <Grid container spacing={2} justifyContent='center' mb={3}>
      {
        waypointsListSorted?.map?.((waypoint) => (
          <AppleHealthCommonChart
            timeFrame={timeFrame}
            waypointId={waypoint.id}
            patientId={patientId}
            providerId={providerId}
            title={waypoint.title ?? ''}
            subtitle={waypoint.subTitle ?? ''}
            type={reverseAppleHealthWaypointMap[waypoint.id] as 'sleepDerived' | 'activityDerived' | 'stepCountDerived'}
            openDrawer={openDrawer}
            key={waypoint.id}
            setCardStatus={updateCardDataStatus}
            onChangeTimeFrame={onChangeTimeFrame}
            numberOfCards={numberOfCards}

          />
        ))
      }
    </Grid>
  );
}
