import { Card, CardContent, Grid, Typography, CardHeader, Box } from '@mui/material';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { SourceTypeMap } from 'pages/Dashboard/types';
import {
  MentalHealthProvider,
  MentalHealthProviderCategory,
  Provider,
} from 'pages/Dashboard/types/providers.types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import theme from 'theme';
import { EProfessions } from 'types/Entitites';

export interface ProviderCardProps extends ShowMoreDrawerChildProps {
  patientProviders?: Provider[];
  mentalHealthProviders?: MentalHealthProvider[];
  primaryCareProviders?: Provider[];
  isNewUi?:boolean
}

const providerCategoryMap: { [key: string]: string } = {
  [MentalHealthProviderCategory.MENTAL_HEALTH_PROVIDER_CATEGORY_PSYCHIATRIST]: 'Psychiatrist',
  [MentalHealthProviderCategory.MENTAL_HEALTH_PROVIDER_CATEGORY_THERAPIST]: 'Therapist',
  [MentalHealthProviderCategory.MENTAL_HEALTH_PROVIDER_CATEGORY_UNSPECIFIED]: 'Other Provider',
};

function getTaxonomyText(currentProvider: MentalHealthProvider): string {
  let taxonomyText = '';

  if (currentProvider.source !== SourceTypeMap.SOURCE_TYPE_PATIENT_REPORTED
    && currentProvider.provider.taxonomyGroup) {
    taxonomyText = currentProvider.provider.taxonomyGroup;
  }
  else if (currentProvider.source === SourceTypeMap.SOURCE_TYPE_PATIENT_REPORTED
    && currentProvider.category
    === MentalHealthProviderCategory.MENTAL_HEALTH_PROVIDER_CATEGORY_UNSPECIFIED
  ) {
    taxonomyText = 'PCP';
  }
  else if (providerCategoryMap?.[currentProvider.category as string]) {
    taxonomyText = providerCategoryMap[currentProvider.category as string];
  }
  else if (currentProvider.provider.designation) {
    taxonomyText = EProfessions[currentProvider.provider.designation as keyof typeof EProfessions];
  }
  else {
    taxonomyText = '';
  }
  return taxonomyText;
}

function ProviderDetailItem({
  currentProvider,
}: {
  currentProvider: MentalHealthProvider;
}): JSX.Element {
  const hasNPINumber = currentProvider?.provider?.npiNumber > 0;
  const ProviderNameContainer = hasNPINumber ? Link : React.Fragment;
  const providerNameColor = hasNPINumber
    ? theme.custom.colors.primaryMain
    : '';
  const lookupURL = `${process.env.REACT_APP_NPI_LOOKUP_BASE_URL}${currentProvider.provider.npiNumber}`;
  let taxonomyText = getTaxonomyText(currentProvider);
  const { provider: { licenseState } } = currentProvider;
  if (licenseState) {
    taxonomyText += ` - ${licenseState}`;
  }
  const providerName = [
    currentProvider.provider.namePrefix,
    currentProvider.provider.firstName,
    currentProvider.provider.lastName,
    currentProvider.provider.credential,
  ]
    .filter(Boolean) // Removes falsy values (e.g., undefined, null, empty string)
    .join(' ');
  return (
    <React.Fragment key={`health-history-${currentProvider.provider.id}`}>
      <Box display='flex' justifyContent='space-between' flex={1} width='100%'>
        <Box display='flex' flexDirection='column'>
          <ProviderNameContainer to={lookupURL} style={{ textDecoration: 'none' }} target='_blank' rel='noopener'>
            <Typography color={providerNameColor} sx={{ textDecoration: 'none', '&:hover': { textDecoration: hasNPINumber ? 'underline' : null } }}>
              {providerName}
            </Typography>
          </ProviderNameContainer>
          <Typography mb={2} variant='body1' color={theme.custom.colors.lightTextSecondary}>
            {taxonomyText}
          </Typography>
        </Box>
        <UserAddedToolTip source={currentProvider?.source} />
      </Box>
    </React.Fragment>
  );
}

export default function ProvidersCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  patientProviders = [],
  mentalHealthProviders = [],
  primaryCareProviders = [],
  onShowMore,
  isNewUi = false,
}: ProviderCardProps): JSX.Element | null {
  const allProviders: Array<Provider & { category?: string }> = [
    ...patientProviders,
  ];

  const data = sliceForShowMore(allProviders, !drawerMode, 6);
  const { Icon } = HealthRecordCategories.providers.value;
  if (allProviders.length === 0 && isNewUi) {
    return null;
  }
  return (
    <Grid item xs={12} sx={{}} md={!drawerMode ? 4 : undefined} key='provider-card'>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'flex-start',
        }}
      >
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title={HealthRecordCategories.providers.value.displayName}
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flexGrow: 1,
            height: '100%',
            width: '100%',
          }}
        >
          <Box display='flex' flexDirection='column' height='100%' width='100%'>
            <Box flexGrow={1} width='100%'>
              {!allProviders.length ? (
                <NoDataForNow />
              ) : (
                data.map((currentProvider) => (
                  <ProviderDetailItem
                    currentProvider={currentProvider as MentalHealthProvider}
                    key={currentProvider.id}
                  />
                ))
              )}
            </Box>
            <Box>
              <ShowMoreButton
                isDrawer={drawerMode}
                onShowMore={onShowMore as VoidFunction}
                items={allProviders}
                showMoreThreshold={6}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
