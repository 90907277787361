import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as spaUrls from 'utils/spaUrls';
import AddPatient from 'pages/Dashboard/AddPatient';
import AddNewPatient from 'pages/Dashboard/AddNewPatient';
import ProvidersList from 'pages/SuperAdmin/ProvidersList';
import ScalesManifest from 'pages/ScalesManifest/ScalesManifest';
import Borders from 'components/Layouts/Borders';
import PrivateRoute from 'components/Common/PrivateRoute';
import PageNotFound from 'pages/Exceptions/PageNotFound';
import AuthRouter from 'pages/Authentication/Router';
import { UserRole } from 'types/NavTreeModel';
import AdminPortal from 'pages/AdminPortal';
import DashboardNew from 'pages/Dashboard/DashboardNew';
import PatientDetailNew from 'pages/Dashboard/PatientDetailNew';
import { useHttp } from 'hooks/use-fetch';

const getPrivateRoutes = (landing: string): {
  [key: string]: {
    roles?: UserRole[];
    component: JSX.Element;
  };
} => ({
  '/auth/*': { component: <AuthRouter /> },
  '/': { component: <Navigate to={landing} replace /> },
  [spaUrls.mainNav.dashboard]: {
    roles: [
      UserRole.PROVIDER,
      UserRole.STAFF,
      UserRole.CHIEF_ADMIN,
      UserRole.BILLING_ADMIN,
      UserRole.SUPER_ADMIN,
    ],
    component: <DashboardNew />,
  },
  [spaUrls.mainNav.patientDetail()]: {
    roles: [
      UserRole.PROVIDER,
      UserRole.STAFF,
      UserRole.CHIEF_ADMIN,
      UserRole.BILLING_ADMIN,
      UserRole.SUPER_ADMIN,
    ],
    component: <PatientDetailNew />,
  },
  [spaUrls.mainNav.addPatient]: {
    roles: [UserRole.PROVIDER, UserRole.STAFF, UserRole.CHIEF_ADMIN, UserRole.SUPER_ADMIN],
    component: <AddPatient />,
  },
  [spaUrls.mainNav.addNewPatient]: {
    roles: [UserRole.PROVIDER, UserRole.STAFF, UserRole.CHIEF_ADMIN, UserRole.SUPER_ADMIN],
    component: <AddNewPatient />,
  },
  [spaUrls.mainNav.reports]: {
    roles: [UserRole.BILLING_ADMIN, UserRole.SUPER_ADMIN],
    component: <ScalesManifest />,
  },
  [spaUrls.adminRoutes.portal]: {
    roles: [UserRole.CHIEF_ADMIN],
    component: <AdminPortal />,
  },
  [spaUrls.superAdminRoutes.providersPracticeList]: {
    roles: [UserRole.SUPER_ADMIN],
    component: <ProvidersList />,
  },
  '/404': { component: <PageNotFound /> },
  '*': { component: <Navigate to='/404' replace /> },
});

export default function Router() {
  const { userRoles } = useHttp();
  const isChiefAdmin = !!userRoles[UserRole.CHIEF_ADMIN];
  const landing = isChiefAdmin ? spaUrls.adminRoutes.portal : spaUrls.mainNav.dashboard;
  const privateRoutes = getPrivateRoutes(landing);
  return (
    <Borders>
      <Routes>
        {Object.entries(privateRoutes).map(([path, element]) => (element.roles ? (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute outlet={element.component} roles={element.roles} />}
          />
        ) : (
          <Route key={path} path={path} element={element.component} />
        )))}
      </Routes>
    </Borders>
  );
}
