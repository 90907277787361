import { useHttp } from 'hooks/use-fetch';
import { fetchWaypointTracking } from 'pages/Dashboard/services/tracking.services';
import { WaypointTrackingAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import useSourceData from 'pages/Dashboard/useSourceData';
import { getChartDateRanges, TimeFrameOptions } from 'pages/Dashboard/utils/trackingUtils';
import React from 'react';
import { useQuery } from 'react-query';
import { trackingUrls } from 'utils/apiUrls';

interface SleepDataProps {
    waypointId: string;
    providerId:string;
    patientId:string;
    timeFrame:string;
}
const useWaypointData = ({
  waypointId,
  providerId,
  patientId,
  timeFrame,

} : SleepDataProps) => {
  const { http } = useHttp();
  const { sourceOptions, sourceId, onChangeSource, sourceObject, isSourceFetched } = useSourceData({
    providerId,
    waypointId,
    patientId,
  });

  const fetchPatientWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}-${sourceId}-${timeFrame}`,
      providerId,
      patientId,
      waypointId,
      timeFrame,
      sourceId,
    ],
    {
      queryFn: fetchWaypointTracking(http.get),
      enabled: !!providerId && !!patientId && !!isSourceFetched && !!waypointId,
    },
  );
  const waypointResponse = fetchPatientWaypointTrackingResult?.data as WaypointTrackingAPIResponse;
  const waypointData = waypointResponse?.waypoints || [];
  const waypointAverageData = React.useMemo(() => {
    const data = waypointResponse || {};
    return {
      value: data?.absoluteAvgValue,
      unit: data?.absoluteValueUnit,
    };
  }, [waypointResponse]);
  const waypointPreviousPeriodAvgData = React.useMemo(() => {
    const data = waypointResponse?.previousIntervalAverages || [];
    return {
      value: data[0]?.value,
      unit: data[0]?.unit,
    };
  }, [waypointResponse]);
  const yAxisProps = getChartDateRanges(timeFrame as TimeFrameOptions) as {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };
  return {
    sourceOptions,
    sourceId,
    onChangeSource,
    chartData: waypointData,
    average: waypointAverageData,
    previousPeriodAverage: waypointPreviousPeriodAvgData,
    yAxisProps,
    isLoading: fetchPatientWaypointTrackingResult.isLoading,
    sourceObject,
  };
};

export default useWaypointData;
