import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueries, useQuery } from 'react-query';
import { Box, Card, CardContent, Fade, Menu, MenuItem, Typography, capitalize } from '@mui/material';
import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import PageHeader from 'components/Common/PageHeader';
import TabComponent, { TabContext, Tabs } from 'components/Common/TabComponent';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import { PatientCtx } from 'pages/Dashboard/constants';
import { Logger } from 'utils/logger';
import {
  allergiesUrls,
  backgroundsUrls,
  diagnosisUrls,
  familyHistoryUrls,
  hospitalizationsUrls,
  labsUrls,
  lifestyleUrls,
  medicationsUrls,
  patientProviderUrls,
  patientUrls,
  providers,
  symptomsUrls,
  trackingUrls,
  whoIam,
} from 'utils/apiUrls';
import { downloadUrl } from 'utils/service';
import {
  fetchPatientExportData,
  fetchWhoIamCategories,
} from 'pages/Dashboard/services/whoiam.services';
import { fetchProvidersAssociationsList } from 'pages/Dashboard/services/provider.services';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { Mood, MoodV2 } from 'pages/Dashboard/types/moods.types';
import {
  fetchDiagnosisListCombined,
} from 'pages/Dashboard/services/diagnosis.services';
import {
  fetchAllMedicationList,
  fetchMedicationChartHistory,
} from 'pages/Dashboard/services/medications.services';
import { fetchPatientDetails, fetchPatientHealthRecordStatus } from 'pages/Dashboard/services/patient.services';
import fetchFamilyHistoryList from 'pages/Dashboard/services/familyhistory.services';
import {
  fetchCurrentSymptomList,
  fetchSymptomList,
} from 'pages/Dashboard/services/symptoms.services';
import fetchAllergyList from 'pages/Dashboard/services/allergies.services';
import fetchHospitalizationList from 'pages/Dashboard/services/hospitalizations.services';
import { fetchLabList } from 'pages/Dashboard/services/labs.services';
import { fetchLifeStyleList } from 'pages/Dashboard/services/lifestyle.services';
import fetchBackgroundList from 'pages/Dashboard/services/backgrounds.services';
import {
  fetchPatientPrimaryCareProviderList,
  fetchPatientMentalHealthProviderList,
  fetchPatientCurrentPrimaryCareProviderList,
  fetchAllPatientProvidersList,
} from 'pages/Dashboard/services/patientprovider.services';
import {
  defaultMoodTracking,
  defaultWaypointTracking,
  TimeFrameOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import PatientTracking from 'pages/Dashboard/PatientTracking';
import PatientProfile from 'pages/Dashboard/PatientProfile';
import {
  fetchMoodAverage,
  fetchMoodChart,
  fetchWaypointsTracking,
} from 'pages/Dashboard/services/tracking.services';
import LoadingButton from 'components/Common/LoadingButton';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import ContentLoader from 'components/Common/ContentLoader';
import { WaypointTrackingAPIResponse, WaypointType } from 'pages/Dashboard/types/waypoints.types';
import ScrollIndicator from 'components/Common/ScrollIndicator';
import HealthRecordNew from 'pages/Dashboard/HealthRecordsNew';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { PatientRecordRetrievalStatus } from 'pages/Dashboard/types';
import theme from 'theme';

export default function PatientDetailNew() {
  const { id } = useParams();
  const { http, providerId, superAdminId, isNewVersionEnabled } = useHttp();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [toastProps, setToastProps] = React.useState<ToastProps | null>(null);
  const [selectTimeFrame, setSelectTimeFrame] = React.useState(
    TimeFrameOptions.LAST_30_DAYS as string,
  );

  const open = Boolean(anchorEl);
  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget ?? null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setToastProps(null);
  };

  const onChangeTimeFrame = (timeFrameValue: string) => {
    trackMixpanelEvent(MixpanelEventName.MOOD_AND_WAYPOINT_TIME_FRAME_CHANGED, {
      'patient id': id ?? '',
      'time frame': timeFrameValue,
    }, true);
    setSelectTimeFrame(timeFrameValue);
  };

  const onError = (message: string) => {
    Logger.error(message);
    setToastProps({
      message,
      open: true,
      type: ToastType.error,
    });
  };

  const medications = useQuery({
    queryKey: [medicationsUrls.listMedications.queryUrl, providerId, id],
    queryFn: fetchAllMedicationList(http.get),
    onError: () => onError('Error fetching patient medications'),
  });

  const enableQueries = React.useMemo(() => !medications?.isFetching, [medications?.isFetching]);

  const medicationChartHistory = useQuery({
    queryKey: [medicationsUrls.medicationHistory.queryUrl, providerId, id],
    queryFn: fetchMedicationChartHistory(http.get),
    onError: () => onError('Error fetching patient medication chart history'),
  });

  const [
    { data: patientDetails, isFetching: patientDetailsFetching, isError: patientDetailsError },
    diagnosis,
    familyHistory,
    symptoms,
    currentSymptoms,
    allergies,
    hospitalizations,
    labs,
    patientProviders,
    lifestyle,
    backgrounds,
    patientPrimaryCareProviders,
    patientMentalHealthProviders,
  ] = useQueries([
    {
      queryKey: [patientUrls.patientDetails.queryUrl, providerId, id],
      queryFn: fetchPatientDetails(http.get),
      onError: () => onError('Error fetching patient details'),
      enabled: enableQueries,
    },
    {
      queryKey: [diagnosisUrls.listDiagnosis.queryUrl, providerId, id],
      queryFn: fetchDiagnosisListCombined(http.get),
      onError: () => onError('Error fetching patient diagnosis'),
      enabled: enableQueries,
    },

    {
      queryKey: [familyHistoryUrls.listFamilyHistory.queryUrl, providerId, id],
      queryFn: fetchFamilyHistoryList(http.get),
      onError: () => onError('Error fetching patient family history'),
      enabled: enableQueries,
    },
    {
      queryKey: [symptomsUrls.listSymptoms.queryUrl, providerId, id],
      queryFn: fetchSymptomList(http.get),
      onError: () => onError('Error fetching patient symptoms'),
      enabled: enableQueries,
    },
    {
      queryKey: [symptomsUrls.listSymptoms.queryUrl, providerId, id, 'current'],
      queryFn: fetchCurrentSymptomList(http.get),
      onError: () => onError('Error fetching patient current symptoms'),
      enabled: enableQueries,
    },
    {
      queryKey: [allergiesUrls.listAllergies.queryUrl, providerId, id],
      queryFn: fetchAllergyList(http.get),
      onError: () => onError('Error fetching patient allergies'),
      enabled: enableQueries,
    },
    {
      queryKey: [hospitalizationsUrls.listHospitalizations.queryUrl, providerId, id],
      queryFn: fetchHospitalizationList(http.get),
      onError: () => onError('Error fetching patient hospitalizations'),
      enabled: enableQueries,
    },
    {
      queryKey: [labsUrls.listLabs.queryUrl, providerId, id],
      queryFn: fetchLabList(http.get),
      onError: () => onError('Error fetching patient labs'),
      enabled: enableQueries,
    },
    {
      queryKey: [patientProviderUrls.listPatientProviders.queryUrl, providerId, id],
      queryFn: fetchAllPatientProvidersList(http.get),
      onError: () => onError('Error fetching patient providers'),
      enabled: enableQueries,
    },
    {
      queryKey: [lifestyleUrls.listLifestyle.queryUrl, providerId, id],
      queryFn: fetchLifeStyleList(http.get),
      onError: () => onError('Error fetching patient lifestyle'),
      enabled: enableQueries,
    },
    {
      queryKey: [backgroundsUrls.listBackgrounds.queryUrl, providerId, id],
      queryFn: fetchBackgroundList(http.get),
      onError: () => onError('Error fetching patient backgrounds'),
      enabled: enableQueries,
    },
    {
      queryKey: [patientProviderUrls.listPatientPrimaryCareProviders.queryUrl, providerId, id],
      queryFn: fetchPatientPrimaryCareProviderList(http.get),
      onError: () => onError('Error fetching patient primary care providers'),
      enabled: enableQueries,
    },
    {
      queryKey: [patientProviderUrls.listPatientMentalHealthProviders.queryUrl, providerId, id],
      queryFn: fetchPatientMentalHealthProviderList(http.get),
      onError: () => onError('Error fetching patient mental health providers'),
      enabled: enableQueries,
    },
    {
      queryKey: [
        patientProviderUrls.listPatientPrimaryCareProviders.queryUrl,
        providerId,
        id,
        'current',
      ],
      queryFn: fetchPatientCurrentPrimaryCareProviderList(http.get),
      onError: () => onError('Error fetching patient current primary care providers'),
      enabled: enableQueries,
    },
  ]);

  const currentMoodAverage = useQuery(
    [`${trackingUrls.moodAverage.queryUrl}`, providerId, id, selectTimeFrame, 'current'],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const previousMoodAverage = useQuery(
    [`${trackingUrls.moodAverage.queryUrl}`, providerId, id, selectTimeFrame, 'previous'],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const whoIAmCategories = useQuery([whoIam.categories.queryUrl, providerId, id], {
    queryFn: fetchWhoIamCategories(http.get),
  });

  const currentWaypoint = useQuery(
    [`${trackingUrls.waypoints.queryUrl}`, providerId, id, selectTimeFrame, 'current'],
    {
      queryFn: fetchWaypointsTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
      enabled: enableQueries,
      select: (data) => {
        const filteredWaypoints = data?.waypoints.filter(
          (waypoint) => waypoint.waypoint.type !== WaypointType.WAYPOINT_TYPE_APPLE_HEALTH,
        );

        return {
          waypoints: filteredWaypoints,
        } as WaypointTrackingAPIResponse;
      },
    },
  );

  const previousWaypoint = useQuery(
    [`${trackingUrls.waypoints.queryUrl}`, providerId, id, selectTimeFrame, 'previous'],
    {
      queryFn: fetchWaypointsTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
      enabled: enableQueries,
      select: (data) => {
        const filteredWaypoints = data?.waypoints.filter(
          (waypoint) => waypoint.waypoint.type !== WaypointType.WAYPOINT_TYPE_APPLE_HEALTH,
        );

        return {
          waypoints: filteredWaypoints,
        } as WaypointTrackingAPIResponse;
      },
    },
  );

  const moodChartHappiness = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      isNewVersionEnabled ? MoodV2.MOOD_PLEASANTNESS : Mood.MOOD_HAPPINESS,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const moodChartAnxiousness = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      Mood.MOOD_ANXIOUSNESS,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const moodChartMotivation = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      isNewVersionEnabled ? MoodV2.MOOD_ENERGY : Mood.MOOD_MOTIVATION,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const { mutateAsync: fetchExportData, isLoading: exportLoading } = useMutation(
    (asPDF?: boolean) => fetchPatientExportData(http.post, providerId, id ?? '', asPDF),
    {
      onSuccess: (dataBlob) => {
        const fileName = `${patientName} Report`;
        const blob = new Blob([dataBlob], {
          type: dataBlob.type === 'application/pdf' ? dataBlob.type : 'application/vnd.ms-excel',
        });
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, fileName);
      },
    },
  );

  const providerAssociations = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'patient-detail'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
    },
  );

  const healthRecordStatus = useQuery(
    [`${patientUrls.patientHealthRecordStatus.queryUrl}`, providerId, id],
    {
      queryFn: fetchPatientHealthRecordStatus(http.get),
    },
  );

  const isMoodLoading = React.useMemo(
    () => moodChartAnxiousness.isLoading
      || moodChartMotivation.isLoading
      || moodChartHappiness.isLoading
      || currentMoodAverage.isLoading
      || previousMoodAverage.isLoading,
    [
      moodChartAnxiousness.isLoading,
      moodChartMotivation.isLoading,
      moodChartHappiness.isLoading,
      currentMoodAverage.isLoading,
      previousMoodAverage.isLoading,
    ],
  );

  const isTrackingLoading = React.useMemo(
    () => currentWaypoint.isLoading
      || previousWaypoint.isLoading
      || isMoodLoading,
    [
      currentWaypoint.isLoading,
      previousWaypoint.isLoading,
      isMoodLoading,
    ],
  );

  const isPatientProfileLoading = React.useMemo(
    () => whoIAmCategories.isLoading,
    [whoIAmCategories.isLoading],
  );

  const defaultCategories = {
    categories: [],
  };

  const patientName = React.useMemo(() => {
    const firstName = capitalize(patientDetails?.patient?.name?.firstName ?? '');
    const lastName = capitalize(patientDetails?.patient?.name?.lastName ?? '');
    return `${firstName} ${lastName}`;
  }, [patientDetails?.patient?.name?.firstName, patientDetails?.patient?.name?.lastName]);

  const providerName = React.useMemo(() => {
    const firstName = capitalize(patientDetails?.patient?.provider?.firstName ?? '');
    const lastName = capitalize(patientDetails?.patient?.provider?.lastName ?? '');
    return `${firstName} ${lastName}`;
  }, [patientDetails?.patient?.provider?.firstName, patientDetails?.patient?.provider?.lastName]);

  const activityText = isNewVersionEnabled ? 'ACTIVITIES' : 'WAYPOINTS';

  const { isLoading } = healthRecordStatus;

  const isRecordStatusRetreived = healthRecordStatus.data?.status?.recordRetrievalStatus
  !== PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_RETRIEVED;

  React.useEffect(() => {
    if (isRecordStatusRetreived) {
      trackMixpanelEvent(MixpanelEventName.HEALTH_RECORDS_FETCHING_IN_PROGRESS, {
        'patient id': id ?? '',
      });
    }
  }, [id, isRecordStatusRetreived]);

  const configureTabs: TabContext[] = [
    {
      header: 'TREATMENT REGIMEN & HISTORY',
      content: (
        <Grid
          pt={isRecordStatusRetreived && !isLoading ? 3 : 7}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            {isRecordStatusRetreived && !isLoading && (
              <Card sx={{ backgroundColor: '#E9F9EA',
                borderRadius: 3,
                marginBottom: 3,
                border: '1px solid',
                boxShadow: 'none',
                width: '50%',
                borderColor: theme.custom
                  .colors.lightSuccessMain }}
              >
                <CardContent>
                  <Box display='flex' alignItems='center' gap={1}>
                    <AutorenewIcon color='success' />
                    <Typography variant='h6'>
                      In progress
                    </Typography>
                  </Box>
                  <Typography variant='body1' marginTop={1.5}>
                    Records are still being retrieved. The information shown may not be complete.
                  </Typography>
                </CardContent>
              </Card>
            )}
            <HealthRecordNew
              diagnosis={diagnosis}
              medications={medications}
              familyHistory={familyHistory}
              symptoms={symptoms}
              currentSymptoms={currentSymptoms}
              allergies={allergies}
              hospitalizations={hospitalizations}
              providers={patientProviders}
              primaryCareProviders={patientPrimaryCareProviders}
              mentalHealthProviders={patientMentalHealthProviders}
              lifeStyle={lifestyle}
              backgrounds={backgrounds}
              labs={labs}
              medicationChartHistory={medicationChartHistory}
              isRecordStatusRetreived={isRecordStatusRetreived}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      header: `MOOD & ${activityText}`,
      content: (
        <Grid pt={4} container spacing={7}>
          <Grid item xs={12}>
            <PatientTracking
              timeFrame={selectTimeFrame}
              onChangeTimeFrame={onChangeTimeFrame}
              currentMoodData={
                currentMoodAverage?.data?.moods ? currentMoodAverage?.data : defaultMoodTracking
              }
              previousMoodData={
                previousMoodAverage?.data?.moods ? previousMoodAverage?.data : defaultMoodTracking
              }
              currentWaypointData={
                currentWaypoint?.data?.waypoints ? currentWaypoint.data : defaultWaypointTracking
              }
              previousWaypointData={
                previousWaypoint?.data?.waypoints ? previousWaypoint?.data : defaultWaypointTracking
              }
              moodChartHappiness={moodChartHappiness?.data || defaultMoodTracking}
              moodChartAnxiousness={moodChartAnxiousness?.data || defaultMoodTracking}
              moodChartMotivation={moodChartMotivation?.data || defaultMoodTracking}
              isMoodLoading={isMoodLoading}
              isTrackingLoading={isTrackingLoading}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      header: 'PATIENT PROFILE',
      content: (
        <Grid mt={2} container spacing={2}>
          <Grid item xs={12}>
            <ContentLoader isFetching={isPatientProfileLoading} minHeight={400} isError={false}>
              <PatientProfile
                categoriesData={whoIAmCategories?.data || defaultCategories}
                backgrounds={backgrounds}
              />
            </ContentLoader>
          </Grid>
        </Grid>
      ),
    },
  ];

  const exportData = (type: string) => {
    handleMenuClose();
    fetchExportData(type === 'pdf');
    trackMixpanelEvent(MixpanelEventName.EXPORT_DATA_CLICKED, {
      'patient id': id ?? '',
      'export type': type,
    });
  };

  const tabs = Object.keys(TimeFrameOptions).map((timeFrameOption) => ({
    header: TimeFrameOptions[timeFrameOption as keyof typeof TimeFrameOptions],
  }));

  const timeFrameValues = React.useMemo(() => Object.values(TimeFrameOptions), []);

  const selectedTimeFrame = React.useMemo(
    () => timeFrameValues.findIndex((value) => value === selectTimeFrame),
    [selectTimeFrame, timeFrameValues],
  );

  return (
    <ContentLoader
      isFetching={!patientDetails || patientDetailsFetching}
      minHeight={400}
      isError={patientDetailsError}
    >
      <PatientCtx.Provider value={patientDetails?.patient as PatientObj}>
        {!!providerAssociations.data?.providers.length && (
          <Box>
            <Typography variant='subtitle1'>{providerName}</Typography>
          </Box>
        )}
        <PageHeader
          title={patientName}
          isMasked
          subtitle={
            googleDateToDateString(patientDetails?.patient?.dateOfBirth as GoogleDate) as string
          }
        />
        <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <LoadingButton
            variant='outlined'
            onClick={handleClick}
            startIcon={<GetAppIcon />}
            isLoading={exportLoading}
            disabled={!!superAdminId}
            label='EXPORT DATA'
          />
          <Menu
            id='fade-menu'
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl as HTMLElement}
            open={open}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
            sx={{ '& .MuiMenu-paper': { width: 152 } }}
          >
            <MenuItem onClick={() => exportData('excel')}>As Excel</MenuItem>
            <MenuItem onClick={() => exportData('pdf')}>As PDF</MenuItem>
          </Menu>
        </Box>
        <TabComponent
          tabContext={configureTabs}
          tabsHeight={48}
          renderBelowHeader={(selectedTab) => (
            configureTabs[selectedTab].header === `MOOD & ${activityText}` ? (
              <Box
                display='flex'
                alignItems='center'
                flexDirection='row'
                justifyContent='flex-end'
                py={3}
              >
                <Typography color='#4C4C4C' marginRight={2}>
                  Data from past:
                </Typography>
                <Tabs
                  value={selectedTimeFrame}
                  setValue={(value) => {
                    const timeFrameValue = timeFrameValues[value as number];
                    onChangeTimeFrame(timeFrameValue);
                    trackMixpanelEvent(MixpanelEventName.DATERANGE_CLICKED, {
                      timeFrameValue,
                      clickedFrom: 'Patient details',
                    });
                  }}
                  tabContext={tabs}
                  variant='standard'
                  activeStyles={{
                    backgroundColor: '#FFF',
                    maxHeight: 24,
                  }}
                />
              </Box>
            ) : null
          )}
        />
        {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}
        <ScrollIndicator />
      </PatientCtx.Provider>
    </ContentLoader>
  );
}
