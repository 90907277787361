import { addDays, format, parse, subYears } from 'date-fns';


const d = new Date();

export type GoogleDate = {
  year: number;
  month: number;
  day: number;
};

export type Time = {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
}

export type GoogleTimestamp = {
  nanos: number;
  seconds: number;
}

export const browserDateFormat = 'yyyy-MM-dd';


export enum Frequency {
  FREQUENCY_UNSPECIFIED = 'FREQUENCY_UNSPECIFIED',
  FREQUENCY_NA = 'FREQUENCY_NA',
  FREQUENCY_HOURLY = 'FREQUENCY_HOURLY',
  FREQUENCY_DAILY = 'FREQUENCY_DAILY',
  FREQUENCY_WEEKLY = 'FREQUENCY_WEEKLY',
  FREQUENCY_MONTHLY = 'FREQUENCY_MONTHLY',
  FREQUENCY_YEARLY = 'FREQUENCY_YEARLY'
}

export const formattedDates = {
  today: format(d, browserDateFormat),
  age18: format(subYears(d, 18), browserDateFormat),
};

export const formatToBrowserDate = (date: Date) => format(date, browserDateFormat);

export const dateStringToGoogleDate = (dateString: string): GoogleDate => {
  const parsedDate = parse(dateString, browserDateFormat, new Date());
  return {
    year: parsedDate.getFullYear(),
    month: parsedDate.getMonth() + 1,
    day: parsedDate.getDate(),
  };
};

export function googleDateToDateString(googleDate: GoogleDate | null, timestamp?: boolean) {
  if (!googleDate) {
    return 'Unknown date';
  }
  const { day, month, year } = googleDate;
  const formatString = `${year}-${month}-${day}`;
  const parsedDateFns = parse(formatString, browserDateFormat, new Date());
  return timestamp ? parsedDateFns.getTime() : format(parsedDateFns, 'M/d/yyyy');
}

export function formatRelativeDate(inputDate: Date): string {
  const now = new Date();
  const date = new Date(inputDate);

  const diffTime = now.getTime() - date.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30);

  if (diffDays === 0) {
    return 'Today';
  }
  else if (diffDays === 1) {
    return 'Yesterday';
  }
  else if (diffDays < 7) {
    return `${diffDays} days ago`;
  }
  else if (diffDays < 30) {
    return diffWeeks === 1 ? '1 week ago' : `${diffWeeks} weeks ago`;
  }
  else {
    return diffMonths === 1 ? '1 month ago' : `${diffMonths} months ago`;
  }
}

export function googleTimestampToDateString(googleTimestamp: GoogleTimestamp | string) {
  if (!googleTimestamp) {
    return '';
  }

  if (typeof googleTimestamp === 'string') {
    return googleDateToDateString(dateTimeStringToGoogleDate(googleTimestamp));
  }

  const date = new Date(googleTimestamp.seconds * 1000);
  return format(date, 'M/d/yyyy');
}

export const frequencyFormatter = (frequency: Frequency) => {
  switch (frequency) {
    case Frequency.FREQUENCY_DAILY:
      return 'per day';
    case Frequency.FREQUENCY_HOURLY:
      return 'per hour';
    case Frequency.FREQUENCY_MONTHLY:
      return 'per month';
    case Frequency.FREQUENCY_WEEKLY:
      return 'per week';
    case Frequency.FREQUENCY_YEARLY:
      return 'per year';
    case Frequency.FREQUENCY_UNSPECIFIED:
      return '';
    case Frequency.FREQUENCY_NA:
      return '';
    default:
      return '';
  }
};

export const dateTimeStringToGoogleDate = (
  dateString: string,
): GoogleDate => {
  const date = dateString.split('T');
  const [year, month, day] = date[0].split('-');

  return {
    year: +year,
    month: +month,
    day: +day,
  };
};

export const subtractDaysFromDate = (date: number, days: number): number => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const subtractedDate = new Date(date - days * millisecondsPerDay);
  return subtractedDate.getTime();
};

export const getHoursFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.getHours(); // Use getUTCHours() if working with UTC time
};

// Function to calculate the difference in hours
export const findNearestHourFromTop = (timestamp: number) => {
  // Get the current hour
  const currentHour = getHoursFromTimestamp(timestamp);
  // Calculate the difference between the current hour and the nearest top hour
  const differenceToTopHour = 1 - (currentHour % 1);
  // Calculate the difference between the current hour and the nearest bottom hour
  const nearestTopHour = currentHour + differenceToTopHour;
  // Return the nearest top hours as an object
  return nearestTopHour;
};

// Function to find the nearest hour from the bottom
export const findNearestHourFromBottom = (timestamp: number) => {
  // Get the current hour
  const currentHour = getHoursFromTimestamp(timestamp);
  // Calculate the difference between the current hour and the nearest bottom hour
  const differenceToBottomHour = currentHour % 1;
  // Calculate the nearest bottom hour
  const nearestBottomHour = currentHour - differenceToBottomHour;
  // Return the nearest bottom hour
  return nearestBottomHour;
};

// Function to calculate the difference in hours
export const getHourDifference = (timestamp1: number, timestamp2: number) => {
  // Extract hours from both timestamps
  const hours1 = findNearestHourFromBottom(timestamp1);
  const hours2 = findNearestHourFromTop(timestamp2);

  // Calculate the difference in hours
  const differenceInHours = hours1 - hours2 > 0 ? 24 - hours1 + hours2 : hours1 - hours2;

  // Return the difference as an integer
  return Math.abs(differenceInHours);
};

export function getStartOfDayLocal(timestamp: number, isInDays = true): number {
  // Create a Date object from the GMT timestamp
  const gmtDate = new Date(timestamp);

  // Extract the local year, month, and day
  const localYear = gmtDate.getFullYear();
  const localMonth = gmtDate.getMonth(); // 0-indexed
  const localDay = gmtDate.getDate();

  // Create a new Date object for the start of the day in local time
  const localStartOfDay = new Date(localYear, localMonth, localDay, 0, 0, 0);

  // Return the timestamp for the start of the local day
  return isInDays
    ? localStartOfDay.getTime()
    : addDays(timestamp, new Date().getDate() - 2).getTime();
}

