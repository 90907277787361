import { useTheme } from '@emotion/react';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import {
  AddPatientPreference,
  CreatePatientFormData,
  InvitePatientFormData,
  PatientContextValues,
  PatientDemographicInfoPayload,
  PatientForm,
  PatientInvitesPayload,
  UserMetadata,
  UserMetadataFlags,
} from 'pages/Dashboard/types';
import Spinner from 'components/Common/Spinner';
import {
  PatientContext,
  patientContextInitialState,
  patientFormInitialState,
  patientReducer,
} from 'pages/Dashboard/AddPatientContext';
import PageHeader from 'components/Common/PageHeader';
import { useMutation, useQuery } from 'react-query';
import { setCreatePatientPayload, setInvitePatientsPayload } from 'utils/service';
import { useHttp } from 'hooks/use-fetch';
import Result from 'pages/Dashboard/types/mixpanel.types';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { createPatient, invitePatients } from 'pages/Dashboard/services/patient.services';
import AddNewPatientForm from 'pages/Dashboard/AddNewPatientForm';
import AddNewPatientSuccess from 'pages/Dashboard/components/AddNewPatientSuccess';
import { providers } from 'utils/apiUrls';
import { fetchUserMetadata, postUserMetadata } from 'pages/Dashboard/services/provider.services';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

export default function AddPatient() {
  const theme = useTheme();
  const { http, providerId } = useHttp();
  const [patientFormDemographic, setPatientFormDemographic] = useState<boolean>(false);
  const [addPatientTime, setAddPatientTime] = React.useState<string>(new Date().toISOString());
  const [toastProps, setToastProps] = React.useState<ToastProps | null>(null);

  const [state, dispatch] = React.useReducer(patientReducer, patientContextInitialState);

  const formMethods = useForm<PatientForm>({
    defaultValues: patientFormInitialState,
  });

  const { setError, getValues } = formMethods;

  const { isFetching: isPreferenceFetching, refetch } = useQuery(providers.metadata.queryUrl, {
    queryFn: fetchUserMetadata(http.post, {
      name: UserMetadataFlags.AddPatientPreference,
      userId: providerId,
    }),
    onSuccess: (data) => {
      if (data.flag) {
        const value = data.flag?.value;
        if (value === AddPatientPreference.Demographic) {
          setPatientFormDemographic(true);
        }
      }
    },
    onError: () => {
      setPatientFormDemographic(false);
    },
    retry: false,
  });

  const setUserMetadataMutation = useMutation({
    mutationFn: (payload: UserMetadata) => postUserMetadata(http.post, { ...payload }),
  });

  const createPatientMutation = useMutation({
    // eslint-disable-next-line max-len
    mutationFn: (payload: PatientDemographicInfoPayload) => createPatient(http.post, providerId, payload),
    onSuccess: (data) => {
      const eventPayload = {
        'datetime requested': addPatientTime,
        'datetime completed': new Date().toISOString(),
        result: data?.success ? Result.SUCCESS : Result.FAIL,
      };

      trackMixpanelEvent(MixpanelEventName.HEALTH_RECORDS_REQUESTED, eventPayload);
    },
    onError: (error) => {
      setToastProps({
        message: (error as Error)?.message,
        open: true,
        type: ToastType.error,
      });
    },
  });

  const invitePatientsMutation = useMutation({
    mutationFn: (payload: PatientInvitesPayload) => invitePatients(http.post, providerId, payload),
    onSuccess: (data) => {
      formMethods.clearErrors();
      if (data?.success) {
        setToastProps({
          message: 'Patient(s) have been successfully invited.',
          open: true,
          type: ToastType.success,
        });
      }
      else {
        const errorMap = {} as Record<string, string>;
        data?.userReason?.forEach(
          (reason: { success: boolean, userId: string, reason: string }) => {
            if (!reason.success) {
              errorMap[reason.userId] = reason.reason;
            }
          },
        );
        const { patients } = getValues();
        patients?.forEach((field, index) => {
          const mobile = field.mobile ? field.mobile.replace(/[^a-zA-Z0-9]/g, '') : '';
          if (field.email && errorMap[field.email]) {
            setError(`patients.${index}.email`, {
              type: 'manual',
              message: errorMap[field.email],
            });
          }
          if (mobile && errorMap[mobile]) {
            setError(`patients.${index}.mobile`, {
              type: 'manual',
              message: errorMap[mobile],
            });
          }
        });
      }
    },
    onError: (error) => {
      setToastProps({
        message: (error as Error)?.message,
        open: true,
        type: ToastType.error,
      });
    },
  });

  const onSubmit: SubmitHandler<PatientForm> = (data) => {
    setAddPatientTime(new Date().toISOString());

    if (patientFormDemographic) {
      const payload = setCreatePatientPayload(data as CreatePatientFormData);
      createPatientMutation.mutate(payload);
    }
    else {
      const payload = setInvitePatientsPayload(data as InvitePatientFormData);
      invitePatientsMutation.mutate(payload);
    }
  };

  const value: PatientContextValues = React.useMemo(
    () => ({
      addingPatient: false,
      dispatch,
      state,
      practicesList: null,
    }),
    [state],
  );

  const invitePatientsSuccess = invitePatientsMutation?.data?.success;

  if (createPatientMutation.isSuccess || invitePatientsSuccess) {
    return (
      <AddNewPatientSuccess
        patientFormDemographic={patientFormDemographic}
        onAddAnotherPatientClick={() => {
          formMethods.reset(patientFormInitialState);
          invitePatientsMutation.reset();
          createPatientMutation.reset();
          refetch();
          setPatientFormDemographic(false);
        }}
      />
    );
  }

  if (isPreferenceFetching) {
    return <Spinner />;
  }

  const setAddPatientPreference = (val: boolean) => {
    setUserMetadataMutation.mutate({
      userId: providerId,
      flag: {
        name: UserMetadataFlags.AddPatientPreference,
        value: val ? AddPatientPreference.Demographic : AddPatientPreference.Invite,
      },
    });
    if (val) {
      formMethods.unregister('patients');
      formMethods.clearErrors();
    }
    else {
      formMethods.register('patients', { value: [{ email: '', mobile: '' }] });
    }
    setPatientFormDemographic(val);
  };

  return (
    <PatientContext.Provider value={value}>
      <Box sx={{ margin: { sm: theme.spacing(3) } }}>
        <PageHeader title='Add a patient' titleVariant='h4' />
        <FormProvider {...formMethods}>
          <AddNewPatientForm
            onSubmit={onSubmit}
            patientFormDemographic={patientFormDemographic}
            setPatientFormDemographic={setAddPatientPreference}
            isLoading={
              createPatientMutation.isLoading || invitePatientsMutation.isLoading
            }
          />
        </FormProvider>
        {toastProps && (
        <Toast
          {...toastProps}
          onClose={() => setToastProps(null)}
        />
        )}
      </Box>
    </PatientContext.Provider>
  );
}
