import * as React from 'react';
import { capitalize, TableCell, Typography } from '@mui/material';
import { NotSpecified } from 'utils/constants';
import { PatientPersonalityTestResultTextProps } from 'pages/Dashboard/types/whoiam.types';

export default function LocusOfControlText({
  result,
  isSummary = false,
}: PatientPersonalityTestResultTextProps): JSX.Element {
  const isBothLocusOfControl = result?.description === 'both';

  const locusOfControlResult = result ? `${capitalize(result.description)} ${isBothLocusOfControl ? 'external and internal locus of control' : 'locus of control'} (${result.score} of ${result.totalScore})` : NotSpecified;
  const Container = isSummary ? TableCell : React.Fragment;

  return (
    <Container>
      <Typography padding={1.25}>
        {locusOfControlResult}
      </Typography>
    </Container>
  );
}
