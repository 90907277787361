import * as React from 'react';
import Box from '@mui/material/Box';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConsentForm from 'components/Layouts/ConsentForm';
import Spinner from 'components/Common/Spinner';
import Footer from 'components/Layouts/Footer';
import Header from 'components/Layouts/Header';
import SideNav from 'components/Layouts/SideNav';
import { SideNavCtx } from 'components/Layouts/constants';
import { FetchContext } from 'hooks/use-fetch';
import { UserRole } from 'types/NavTreeModel';

export const PADDING_RIGHT = '8.5%';

export const PADDING_LEFT = { xs: 1.5, sm: 2.75, xl: 16 };

export default function Borders({ children }: { children: React.ReactNode }) {
  const {
    userRoles,
  } = React.useContext(FetchContext);
  const {
    breakpoints,
    custom: {
      appBarHeight,
      sidebarWidth,
      smSidebarWidth,
      backgroundColor,
    },
  } = useTheme();

  const [scrollPosition, setScrollPosition] = React.useState<number>(0);
  const [isScrolledToBottom, setIsScrolledToBottom] = React.useState<boolean>(false);
  const { isAuthenticated, isLoading } = useAuth0();
  const isFullNav = useMediaQuery(breakpoints.up('lg'));
  const drawerWidth = isFullNav ? sidebarWidth : smSidebarWidth;
  const sideNavCtxValue = React.useMemo(
    () => ({ drawerWidth, isFullNav, scrollPosition, isScrolledToBottom }),
    [drawerWidth, isFullNav, scrollPosition, isScrolledToBottom],
  );
  const isChiefAdmin = !!userRoles[UserRole.CHIEF_ADMIN];

  const boxRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);

  const handleScroll = React.useCallback(() => {
    if (boxRef.current && footerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = boxRef.current;
      const hasScrolledToBottom = scrollTop
      > scrollHeight
        - (clientHeight
        + footerRef.current.clientHeight);
      setScrollPosition(scrollTop);
      setIsScrolledToBottom(hasScrolledToBottom);
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  else if (!isAuthenticated) {
    return <Box>{children}</Box>;
  }
  return (
    <SideNavCtx.Provider value={sideNavCtxValue}>
      <Header />
      <Box mt={appBarHeight} height={`calc(100vh - ${appBarHeight})`}>
        <ConsentForm>
          {!isChiefAdmin && <SideNav />}
          <Box
            component='main'
            ml={isChiefAdmin ? 0 : drawerWidth}
            pl={isChiefAdmin ? 2 : PADDING_LEFT}
            pt={2}
            pr={isChiefAdmin ? 2 : PADDING_RIGHT}
            bgcolor={backgroundColor}
            height='100%'
            overflow='auto'
            ref={boxRef}
            onScroll={handleScroll}
          >
            {children}
            <Footer footerRef={footerRef} />
          </Box>
        </ConsentForm>
      </Box>
    </SideNavCtx.Provider>
  );
}
