import React, { useEffect, useMemo, useState } from 'react';
import { Box, Drawer, Typography, Tabs, Tab, Tooltip as MuiTooltip, Slider, styled, keyframes } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, Text } from 'recharts';
import Theme from 'theme';
import { PatientMedications } from 'pages/Dashboard/types/medication.types';
import { formatTickLabel, generateTicks, minDistanceCalculation } from 'utils/medicationChartTimelineUtil';
import { googleDateToDateString } from 'utils/dateUtils';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

const baseHeight = 300;
const additionalHeightPerPoint = 50;

const bounceKeyframe = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const IndicatorContainer = styled(Box)(() => ({
  position: 'absolute',
  bottom: 60,
  right: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${bounceKeyframe} 0.6s ease`,
  animationDelay: '3s',
  animationFillMode: 'forwards',
  zIndex: 1000,

  '&:hover': {
    animation: 'none',
  },
}));

const IndicatorCircle = styled(Box)(() => ({
  width: 48,
  height: 48,
  backgroundColor: Theme.custom.colors.backgroundColorSecondary,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface ChartDrawerProps {
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
  medicationTimeLines: PatientMedications;
  handleSelectedMedication: (medicationId: string,
    medicationName: string,
    medicationMetaId: string,
    patientId: string) => void;
}

type ChartDataPoint = {
  date: number | null;
  name: string;
  startDate?: number;
  endDate?: number;
  active?: number;
};

const convertToDate = (date: { year: number; month?: number; day: number }) => {
  if (!date || !date.year || !date.day || !date.month) {
    return null;
  }
  return new Date(date.year, date.month - 1, date.day);
};

interface CustomTooltipProps {
  active?: boolean;
  label?: string
  activeTooltipData: {
    hoveredPoints: ChartDataPoint[] | null;
    tooltipMedicationName: string | null;
  }
  coordinate?: {
    x: number;
    y: number;
};
}

function CustomTooltip({ active, activeTooltipData, label, coordinate }: CustomTooltipProps) {
  if (active && activeTooltipData && activeTooltipData.tooltipMedicationName) {
    const { y } = coordinate ?? { y: 0 };
    const { tooltipMedicationName, hoveredPoints } = activeTooltipData;
    const hoveredDate = label ? new Date(label) : null;
    // Find the point that matches the hovered date, we plot one point for each quater
    const matchingPoint = hoveredPoints?.find((point) => {
      if (point?.startDate && point?.endDate) {
        const startDate = new Date(point.startDate);
        const endDate = new Date(point.endDate);
        const rangeStart = new Date(startDate);
        rangeStart.setDate(rangeStart.getDate() - 30);
        const rangeEnd = new Date(endDate);
        rangeEnd.setDate(rangeEnd.getDate() + 30);
        return hoveredDate && hoveredDate >= rangeStart && hoveredDate <= rangeEnd;
      }
      return false;
    });

    const formattedStartDate = matchingPoint?.startDate
      && googleDateToDateString(
        {
          year: new Date(matchingPoint.startDate).getFullYear(),
          month: new Date(matchingPoint.startDate).getMonth() + 1,
          day: new Date(matchingPoint.startDate).getDate(),
        },
        false,
      );

    const formattedEndDate = matchingPoint?.endDate
      && googleDateToDateString(
        {
          year: new Date(matchingPoint.endDate).getFullYear(),
          month: new Date(matchingPoint.endDate).getMonth() + 1,
          day: new Date(matchingPoint.endDate).getDate(),
        },
        false,
      );

    return (
      <div
        className='custom-tooltip'
        style={{
          position: 'relative',
          transform: y < 50 ? 'translate(-55%, 10%)' : 'translate(-100%, -140%)',
          backgroundColor: Theme.custom.tooltipBG,
          borderRadius: '4px',
          padding: '4px 12px',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
          fontSize: '14px',
          color: Theme.custom.colors.lightTextPrimary,
          maxWidth: '300px',
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: 'bold',
            color: Theme.custom.colors.lightTextPrimary,
            fontSize: '14px',
          }}
        >
          {tooltipMedicationName}
        </p>
        {matchingPoint && (
          <p style={{ margin: 0, color: Theme.custom.colors.lightTextPrimary }}>
            {`${formattedStartDate}${formattedEndDate && formattedEndDate !== formattedStartDate ? ` - ${formattedEndDate}` : ''}`}
          </p>
        )}
        <div
          style={{
            position: 'absolute',
            top: y < 50 ? '-8px' : 'auto',
            bottom: y < 50 ? 'auto' : '-8px',
            right: y < 50 ? '50%' : 0,
            transform: y < 50 ? 'translateX(50%)' : 'translateX(-50%)',
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: y < 50 ? 'none' : '8px solid #F1F1F1',
            borderBottom: y < 50 ? '8px solid #F1F1F1' : 'none',
          }}
        />
      </div>
    );
  }
  return null;
}

interface CustomYAxisTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
  medicationTimeLines: PatientMedications;
  handleSelectedMedication: (medicationId: string,
    medicationName: string,
    medicationMetaId: string,
    patientId: string) => void;
}

function addSuperscriptR(str: string) {
  return str.replace(/\(([^)]+)\)$/, '($1®)');
}

function CustomYAxisTick({ x, y, payload, medicationTimeLines,
  handleSelectedMedication }: CustomYAxisTickProps) {
  const [isHovered, setIsHovered] = useState(false);
  const originalLabel = payload?.value || '';
  const label = addSuperscriptR(originalLabel);
  const medication = medicationTimeLines.patientMedicationTimelines
    ?.find((med) => med.medicationMetaName === originalLabel);
  const isPsychMedication = medication?.isPsychMedication;
  const textColor = isPsychMedication ? Theme.custom.colors.primaryMain
    : Theme.custom.colors.lightTertiaryMain;

  const maxLines = 2;
  const maxCharsPerLine = 18;
  const words = label.split(' ');
  const lines: string[] = [];
  let currentLine = '';

  for (const word of words) {
    if ((`${currentLine} ${word}`).trim().length <= maxCharsPerLine) {
      currentLine = `${currentLine} ${word}`.trim();
    }
    else {
      lines.push(currentLine);
      currentLine = word;

      if (lines.length === maxLines) {
        break;
      }
    }
  }

  if (lines.length < maxLines && currentLine) {
    lines.push(currentLine);
  }

  const showEllipsis = words.join(' ').length > lines.join(' ').length;
  const handleClick = () => {
    handleSelectedMedication(
      medication?.patientMedicationId as string,
      originalLabel,
      medication?.medicationMetaId as string,
      medicationTimeLines?.patientId,
    );
    trackMixpanelEvent(MixpanelEventName.VIEW_CHARTS_MEDICATION_CLICKED);
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <MuiTooltip title={label} placement='top'>
        <text
          x={0}
          y={0}
          textAnchor='end'
          fill={textColor}
          fontSize={14}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100px',
            whiteSpace: 'pre',
            cursor: 'pointer',
            textDecoration: isHovered ? 'underline' : 'none',
          }}
          onClick={handleClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {lines.map((line, lineIndex) => (
            <tspan
              key={line}
              x={0}
              dy={lineIndex * 15}
            >
              {line}
              {lineIndex === maxLines - 1 && showEllipsis ? '...' : ''}
            </tspan>
          ))}
        </text>
      </MuiTooltip>
    </g>
  );
}

function renderLegendContent(selectedTab: number) {
  const legends = [
    { color: Theme.custom.colors.primaryMain, label: 'Psych Medications' },
    { color: Theme.custom.colors.lightTertiaryMain, label: 'Other Medications' },
  ];

  return legends.reduce<JSX.Element[]>((acc, { color, label }, index) => {
    if (selectedTab === 2 || selectedTab === index) {
      acc.push(
        <div key={label} style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{
            display: 'inline-block',
            width: 12,
            height: 12,
            backgroundColor: color,
            borderRadius: '50%',
            marginRight: 15,
          }}
          />
          <Text style={{ color: '#000000', fontSize: 16, marginRight: selectedTab === 2 && index === 0 ? 15 : 0 }}>
            {label}
          </Text>
        </div>,
      );
    }
    return acc;
  }, []);
}

function getNoTimelineCount(
  selectedTab: number,
  noTimelinePsychCount: number,
  noTimelineOtherCount: number,
  noTimelineBothCount: number,
) {
  if (selectedTab === 0) {
    return noTimelinePsychCount;
  }
  if (selectedTab === 1) {
    return noTimelineOtherCount;
  }
  return noTimelineBothCount;
}

function MedicationChartDrawer({ isOpen, toggleDrawer, medicationTimeLines,
  handleSelectedMedication }: ChartDrawerProps) {
  const boxContainerRef = React.useRef<HTMLDivElement>(null);

  const [selectedTab, setSelectedTab] = useState(2);
  const [tabsScrolled, setTabsScrolled] = useState({} as Record<number, boolean>);
  const [activeTooltipData, setActiveTooltipData] = useState<{
    tooltipMedicationName: string | null;
    hoveredPoints: ChartDataPoint[] | null;
  }>({
    tooltipMedicationName: null,
    hoveredPoints: null,
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [boxHeight, setBoxHeight] = useState(0);

  const handleSelectedTab = (event: React.SyntheticEvent, tab: number) => {
    event.preventDefault();
    setSelectedTab(tab);
    if (tab === 0) {
      trackMixpanelEvent(MixpanelEventName.MEDICATION_CHART_PSYCH_TAB_CLICKED, {
        count: (psychCount - noTimelinePsychCount).toString(),
      });
    }
    else if (tab === 1) {
      trackMixpanelEvent(MixpanelEventName.MEDICATION_CHART_OTHER_TAB_CLICKED, {
        count: (otherCount - noTimelineOtherCount).toString(),
      });
    }
    else {
      trackMixpanelEvent(MixpanelEventName.MEDICATION_CHART_BOTH_TAB_CLICKED, {
        count: (bothCount - noTimelineBothCount).toString(),
      });
    }
    if (boxContainerRef.current) boxContainerRef.current.scrollTop = 0;
  };

  const chartData = useMemo(() => {
    const allData: ChartDataPoint[] = [];

    medicationTimeLines?.patientMedicationTimelines?.forEach((medication) => {
      const { medicationMetaName, timelines } = medication;

      timelines.forEach((timeline) => {
        const { startDate, endDate } = timeline;
        const start = convertToDate(startDate);
        const end = convertToDate(endDate);

        if (start && end) {
          const currentDate = new Date(start);
          const startTime = new Date(start).getTime();
          const endTime = new Date(end).getTime();
          while (currentDate <= end) {
            allData.push({
              date: currentDate.getTime(),
              name: medicationMetaName,
              active: 1,
              startDate: startTime,
              endDate: endTime,
            });
            currentDate.setDate(currentDate.getDate() + 30);
          }
          allData.push({
            date: null,
            name: medicationMetaName,
            active: 0,
          });
        }
      });
    });

    return allData;
  }, [medicationTimeLines]);

  const [domainValue, setDomainValue] = React.useState<number[]>([]);

  const updateYAxisBackground = () => {
    const allAxis = document.querySelectorAll('.recharts-yAxis');

    allAxis?.forEach((axis) => {
      const orientation = axis
        .querySelector('.recharts-cartesian-axis-tick-line')
        ?.getAttribute('orientation');

      // Adding a rect
      const rect = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect',
      );
      const yAxisheight = axis.getBoundingClientRect().height;

      rect.setAttribute('x', '0');
      rect.setAttribute('y', '0');
      rect.setAttribute('width', `${160}`);
      rect.setAttribute('height', `${yAxisheight}`);
      rect.setAttribute('fill', 'white');
      rect.setAttribute('class', `y-axis-rect-${orientation}`);

      axis.insertBefore(rect, axis.firstChild);
    });
  };

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], ticks[ticks.length - 1] - minDistance);
        setDomainValue([clamped, clamped + minDistance]);
      }
      else {
        const clamped = Math.max(newValue[1], ticks[0] + minDistance);
        setDomainValue([clamped - minDistance, clamped]);
      }
    }
    else {
      setDomainValue(newValue as number[]);
    }
    updateYAxisBackground();
  };

  const handleSliderChangeCommitted = (
    event: Event | React.SyntheticEvent<Element, Event>,
    newValue: number | number[],
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    // Capture the final slider value when dragging stops
    const startDate = new Date(newValue[0]);
    const endDate = new Date(newValue[1]);

    trackMixpanelEvent(MixpanelEventName.TIMELINE_SLIDER_MEDICATION_CHART_DRAGGED, {
      'slider value': `${googleDateToDateString({ year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate() }, false)} - ${googleDateToDateString({ year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate() }, false)}`,
    });
  };

  const filteredData = useMemo(() => {
    let data = [];

    if (selectedTab === 0) {
      data = chartData.filter((item) => medicationTimeLines.patientMedicationTimelines
        .some((medication) => medication.isPsychMedication && medication
          .medicationMetaName === item.name));
    }
    else if (selectedTab === 1) {
      data = chartData.filter((item) => medicationTimeLines.patientMedicationTimelines
        .some((medication) => !medication.isPsychMedication && medication
          .medicationMetaName === item.name));
    }
    else {
      data = chartData;
    }

    if (data.length > 0) {
      data.unshift({ date: null, name: '  ', active: 0 });
    }

    return data;
  }, [chartData, selectedTab, medicationTimeLines]);

  const allDates = useMemo(
    () => filteredData.map((item) => item.date).filter((date): date is number => date !== null),
    [filteredData],
  );

  const minDate = useMemo(() => new Date(Math.min(...allDates)), [allDates]);
  const maxDate = useMemo(() => new Date(Math.max(...allDates)), [allDates]);

  const ticks = useMemo(() => generateTicks(minDate, maxDate), [minDate, maxDate]);

  const minDistance = useMemo(() => {
    const totalYears = maxDate.getFullYear() - minDate.getFullYear();
    return minDistanceCalculation(totalYears);
  }, [minDate, maxDate]);

  useEffect(() => {
    if (ticks.length > 0) {
      setDomainValue([ticks[0], ticks[ticks.length - 1]]);
    }
  }, [ticks]);

  const psychCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => medication.isPsychMedication).length, [medicationTimeLines]);
  const otherCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => !medication.isPsychMedication).length, [medicationTimeLines]);
  const bothCount = medicationTimeLines.patientMedicationTimelines?.length;

  const noTimelinePsychCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => medication.isPsychMedication && medication
      .timelines.length === 0).length, [medicationTimeLines]);
  const noTimelineOtherCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => !medication.isPsychMedication && medication
      .timelines.length === 0).length, [medicationTimeLines]);
  const noTimelineBothCount = useMemo(() => medicationTimeLines.patientMedicationTimelines
    ?.filter((medication) => medication.timelines.length === 0).length, [medicationTimeLines]);

  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    toggleDrawer(false);
  };

  const dataCount = React.useMemo(() => [
    ...new Set(filteredData.map((item) => item.name)),
  ].length, [filteredData]);
  const chartHeight = useMemo(() => baseHeight + dataCount * additionalHeightPerPoint, [dataCount]);

  useEffect(() => {
    if (filteredData.length > 0 && isOpen) {
      setTimeout(() => {
        if (boxContainerRef.current) {
          setBoxHeight(boxContainerRef.current.clientHeight);
        }
      }, 0);
    }
  }, [filteredData.length, isOpen]);

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={handleDrawerClose}
      sx={{
        height: '100%',
        width: '75%',
        '& .MuiDrawer-paper': { width: '75%', boxSizing: 'border-box' },
      }}
    >
      <Box display='flex' flexDirection='column' flex={1} overflow='hidden'>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          p={2}
          pb={3}
          mt={1}
          mb={1}
          alignItems='center'
          justifyContent='space-between'
          height={10}
          sx={{ borderBottom: `1px solid ${Theme.custom.colors.backgroundColorSecondary}` }}
        >
          <Typography fontSize={20} fontWeight={700}>
            Medication History
          </Typography>
          <Close sx={{ color: '#0000008A' }} onClick={handleDrawerClose} />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          overflow='hidden'
          flex={1}
          marginTop={2}
          marginBottom={5}
        >
          <Box
            display='flex'
            flexShrink={0}
            width={300}
            marginLeft={3}
            sx={{
              borderWidth: 1,
              border: '1px solid',
              borderColor: Theme.custom.darkTextPrimary,
              borderRadius: '4px',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleSelectedTab}
              centered
              TabIndicatorProps={{ style: { display: 'none' } }}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '& .MuiTab-root': {
                  flex: 1,
                  fontWeight: 500,
                  fontSize: '15px',
                  borderRight: '1px solid',
                  borderRightColor: Theme.custom.darkTextPrimary,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  color: Theme.custom.darkTextPrimary,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  height: '42px',
                  '&:last-of-type': {
                    borderRight: 'none',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#0288D11F',
                    color: Theme.custom.darkTextPrimary,
                  },
                },
              }}
            >
              <Tab label={`PSYCH (${psychCount - noTimelinePsychCount})`} value={0} />
              <Tab label={`OTHER (${otherCount - noTimelineOtherCount})`} value={1} />
              <Tab label={`BOTH (${bothCount - noTimelineBothCount})`} value={2} />
            </Tabs>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            flex='0 1 auto'
            overflow='hidden'
            marginLeft={2}
            marginTop={2}
            position='relative'
          >
            {filteredData.length === 0 && (
              <Box>
                <NoDataForNow />
              </Box>
            )}
            {filteredData.length > 0 && (
              <Box display='flex' flexDirection='column' overflow='hidden'>
                <Box
                  overflow={boxHeight < chartHeight ? 'hidden scroll' : 'hidden'}
                  onScroll={(e) => {
                    const { scrollTop } = e.currentTarget;
                    if (scrollTop > 100 && !tabsScrolled[selectedTab]) {
                      setTabsScrolled((prev) => ({ ...prev, [selectedTab]: true }));
                    }
                    setScrollPosition(scrollTop);
                  }}
                  ref={boxContainerRef}
                  paddingTop={7}
                >
                  {scrollPosition < 100
                    && boxHeight < chartHeight
                    && !tabsScrolled[selectedTab] && (
                      <IndicatorContainer>
                        <MuiTooltip title='Scroll down for more' arrow>
                          <IndicatorCircle>
                            <KeyboardArrowDownIcon
                              fontSize='large'
                              style={{ color: Theme.custom.colors.lightTextSecondary }}
                            />
                          </IndicatorCircle>
                        </MuiTooltip>
                      </IndicatorContainer>
                  )}
                  <ResponsiveContainer width='100%' height={chartHeight}>
                    <LineChart
                      data={filteredData}
                      margin={{ top: 25, right: 30, left: 45, bottom: 13 }}
                    >
                      <CartesianGrid strokeDasharray='0 0' />
                      <XAxis
                        dataKey='date'
                        type='number'
                        domain={[domainValue[0], domainValue[1]]}
                        tickFormatter={(unixTime) => `${formatTickLabel(unixTime, maxDate, minDate)}`}
                        dy={10}
                        allowDataOverflow
                        hide
                        tickSize={0}
                        ticks={ticks}
                      />
                      <YAxis
                        dataKey='name'
                        type='category'
                        padding={{ top: 0.5 }}
                        width={120}
                        tickMargin={6}
                        allowDataOverflow={false}
                        tickSize={0}
                        axisLine
                        tick={(
                          <CustomYAxisTick
                            medicationTimeLines={medicationTimeLines}
                            handleSelectedMedication={handleSelectedMedication}
                          />
                        )}
                      />
                      <Tooltip
                        content={<CustomTooltip activeTooltipData={activeTooltipData} />}
                        allowEscapeViewBox={{ x: true, y: true }}
                        cursor={false}
                      />
                      {[...new Set(filteredData.map((item) => item.name))].map((medicationName) => {
                        const medication = medicationTimeLines.patientMedicationTimelines?.find(
                          (med) => med.medicationMetaName === medicationName,
                        );
                        const isPsychMedication = medication?.isPsychMedication;
                        return (
                          <Line
                            key={medicationName}
                            type='monotone'
                            dataKey='name'
                            style={{ zIndex: 0 }}
                            stroke={isPsychMedication ? Theme.custom.colors.primaryMain
                              : Theme.custom.colors.lightTertiaryMain}
                            dot={false}
                            connectNulls={false}
                            data={filteredData.filter((point) => point.name === medicationName)}
                            activeDot={false}
                            isAnimationActive={false}
                            strokeLinecap='round'
                            strokeWidth={15}
                            onMouseEnter={() => {
                              const hoveredDataPoint = filteredData.filter(
                                (point) => point.name === medicationName,
                              );
                              if (hoveredDataPoint) {
                                setActiveTooltipData({
                                  tooltipMedicationName: medicationName,
                                  hoveredPoints: hoveredDataPoint,
                                });
                              }
                            }}
                            onMouseLeave={() => {
                              setActiveTooltipData({
                                tooltipMedicationName: null,
                                hoveredPoints: null,
                              });
                            }}
                          />
                        );
                      })}
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
                <Box
                  zIndex={10}
                  paddingLeft={15}
                  marginTop={-2.5}
                  flex='0 1 auto'
                  sx={{
                    backgroundColor: 'white',
                  }}
                >
                  <ResponsiveContainer width='100%' height={50}>
                    <LineChart data={filteredData} margin={{ right: 45, left: 45, bottom: 20 }}>
                      <XAxis
                        dataKey='date'
                        type='number'
                        domain={() => [domainValue[0], domainValue[1]]}
                        tickFormatter={(unixTime) => `${formatTickLabel(unixTime, maxDate, minDate)}`}
                        dy={10}
                        allowDataOverflow
                        ticks={ticks}
                        tickSize={0}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            )}
          </Box>
          {filteredData.length > 0 && (
            <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              justifyContent='space-between'
              width='100%'
              marginTop={5}
            >
              <Typography color={Theme.custom.colors.lightTextSecondary} fontSize={16} ml={2}>
                Records without dates:
                <Typography
                  color={Theme.custom.colors.lightTextPrimary}
                  display='inline-block'
                  ml={1.5}
                >
                  {getNoTimelineCount(
                    selectedTab,
                    noTimelinePsychCount,
                    noTimelineOtherCount,
                    noTimelineBothCount,
                  )}
                </Typography>
              </Typography>
              <Box
                flex={1}
                justifyContent='center'
                alignItems='center'
                display='flex'
                flexDirection='column'
              >
                <Slider
                  getAriaLabel={() => 'Minimum distance'}
                  value={domainValue}
                  onChange={handleSliderChange}
                  valueLabelDisplay='on'
                  valueLabelFormat={(v) => `${formatTickLabel(v, maxDate, minDate)}`}
                  getAriaValueText={(v) => `${v}`}
                  disableSwap={false}
                  min={ticks[0]}
                  max={ticks[ticks.length - 1]}
                  sx={{ width: 500,
                    '&.MuiSlider-thumb': {
                      pointerEvents: 'auto',
                    },
                  }}
                  onChangeCommitted={handleSliderChangeCommitted}
                />
                <Box display='flex' marginTop={2}>
                  {renderLegendContent(selectedTab)}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default MedicationChartDrawer;
