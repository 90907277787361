import { useHttp } from 'hooks/use-fetch';
import { fetchWaypointTracking } from 'pages/Dashboard/services/tracking.services';
import { WaypointTracking, WaypointTrackingAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import useSourceData from 'pages/Dashboard/useSourceData';
import { getChartDateRanges, TimeFrameOptions } from 'pages/Dashboard/utils/trackingUtils';
import React from 'react';
import { useQuery } from 'react-query';
import { trackingUrls } from 'utils/apiUrls';

interface SleepDataProps {
    waypointId: string;
    providerId:string;
    patientId:string;
    timeFrame:string;
}
const useActivityData = ({
  waypointId,
  providerId,
  patientId,
  timeFrame,
} : SleepDataProps) => {
  const { sourceId, sourceOptions, onChangeSource, sourceObject, isSourceFetched } = useSourceData({
    providerId,
    waypointId,
    patientId,
  });
  const { http } = useHttp();
  const fetchActivityData = useQuery(
    [
      `${trackingUrls.waypoint.queryUrl}-${sourceId}-${timeFrame}`,
      providerId,
      patientId,
      waypointId,
      timeFrame,
      sourceId,
    ],
    {
      queryFn: fetchWaypointTracking(http.get),
      enabled:
            !!providerId && !!patientId && !!isSourceFetched && !!waypointId,
    },
  );
  const appleHealthActivity = React.useMemo(
    () => fetchActivityData
      ?.data as WaypointTrackingAPIResponse,
    [fetchActivityData],
  );
  const appleHealthActivityData = React.useMemo(
    () => appleHealthActivity?.waypoints || [],
    [appleHealthActivity?.waypoints],
  );

  const appleHealthActivityGoals = React.useMemo(
    () => appleHealthActivity?.goals || [],
    [appleHealthActivity?.goals],
  );

  const appleHealthActivityAverageData = React.useMemo(
    () => appleHealthActivity?.averages || [],
    [appleHealthActivity?.averages],
  );

  const appleHealthActivityPreviousPeriodAverageData = React.useMemo(
    () => appleHealthActivity?.previousIntervalAverages || [],
    [appleHealthActivity?.previousIntervalAverages],
  );
  const hasStandHours = React.useMemo(
    () => appleHealthActivityData?.some((w: WaypointTracking) => w?.metadata?.some((m) => m?.key === 'appleStandHours' && parseFloat(m?.value) > -1)),
    [appleHealthActivityData],
  );
  const barSize = 10;
  const appleHealthActivityAvgData = React.useMemo(() => {
    const data = appleHealthActivity || {};
    return {
      value: data?.absoluteAvgValue,
      unit: data?.absoluteValueUnit,
    };
  }, [appleHealthActivity]);
  const yAxisProps = getChartDateRanges(timeFrame as TimeFrameOptions) as {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };
  return {
    appleHealthActivityData,
    appleHealthActivityGoals,
    appleHealthActivityAverageData,
    appleHealthActivityPreviousPeriodAverageData,
    appleHealthActivityAvgData,
    hasStandHours,
    barSize,
    sourceOptions,
    sourceId,
    onChangeSource,
    yAxisProps,
    isLoading: fetchActivityData.isLoading,
    sourceObject,
  };
};

export default useActivityData;
