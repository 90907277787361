import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface ConfirmPopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message?: string;
}

function ConfirmPopup({
  open,
  onClose,
  onConfirm,
  title,
  message,
}: ConfirmPopupProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='confirm-dialog-title'
      aria-describedby='confirm-dialog-description'
      maxWidth='md'
    >
      <DialogTitle id='confirm-dialog-title'>{title}</DialogTitle>
      {message && (
      <DialogContent>
        <DialogContentText id='confirm-dialog-description'>
          {message}
        </DialogContentText>
      </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={onConfirm} color='primary' autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmPopup;
