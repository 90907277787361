import { Box, Typography } from '@mui/material';
import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AppleHealthTooltip from 'components/Common/AppleHealthTooltip';
import { ReactComponent as HealthConnectImage } from 'assets/HealthConnect.svg';
import { WaypointSource } from 'pages/Dashboard/types/waypoints.types';

function AppleHealthIcon({ sourceObject }:{sourceObject? :Partial<WaypointSource>}) {
  const text = sourceObject?.sourcePlatform === 'ios' ? 'Data from Apple Health' : 'Data from Health Connect';
  return (
    <AppleHealthTooltip
      text={<Typography variant='body2'>{text}</Typography>}
      icon={sourceObject?.sourcePlatform === 'android' ? (
        <HealthConnectImage width={40} height={40} />

      ) : (
        <Box width={24} height={24} bgcolor='white' borderRadius={1} border='1px solid #00000033' position='relative'>
          {/* TODO: Fix border color */}
          <FavoriteIcon color='error' sx={{ fontSize: 12, position: 'absolute', right: 2, top: 2 }} />
        </Box>
      )}
    />
  );
}

export default AppleHealthIcon;
