import { useHttp } from 'hooks/use-fetch';
import { fetchWaypointSources } from 'pages/Dashboard/services/tracking.services';
import { WaypointSource } from 'pages/Dashboard/types/waypoints.types';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { trackingUrls } from 'utils/apiUrls';

const useSourceData = ({
  providerId,
  waypointId,
  patientId,

}:{
    providerId: string;
    waypointId: string;
    patientId: string;
  }) => {
  const [sourceId, setSourceId] = useState('');
  const [sourceObject, setSourceObject] = useState<Partial<WaypointSource>>({});
  const { http } = useHttp();

  const queryKey = React.useMemo(
    () => [trackingUrls.waypointSources.queryUrl, providerId, patientId, waypointId],
    [providerId, patientId, waypointId],
  );
  const fetchPatientWaypointSourcesResult = useQuery(
    queryKey,
    {
      queryFn: fetchWaypointSources(http.get),
      enabled: !!providerId && !!patientId && !!waypointId,
    },
  );


  const sourceOptions = React.useMemo(
    () => fetchPatientWaypointSourcesResult?.data?.sources?.map((s) => ({
      ...s,
      label: s.sourceName,
      value: s.sourceId,
      isSelected: s.isSelected,
    })) ?? [],
    [fetchPatientWaypointSourcesResult?.data?.sources],
  );
  const onChangeSource = useCallback((id:string) => {
    setSourceId(id);
    const selectedSource = sourceOptions.find((s) => s.value === id);
    setSourceObject(selectedSource ?? {});
  }, [sourceOptions]);

  React.useEffect(() => {
    if (fetchPatientWaypointSourcesResult?.data?.sources) {
      const newSources = fetchPatientWaypointSourcesResult
        .data.sources.filter((s:WaypointSource) => s.sourceId?.length > 0);
      if (newSources?.length > 0) {
        newSources[0].isSelected = true;
      }
      const selectedSource = newSources
        .find((s:WaypointSource) => s.isSelected);
      if (!sourceId) {
        setSourceObject(selectedSource ?? {});

        setSourceId(selectedSource?.sourceId ?? '');
      }
    }
  }, [fetchPatientWaypointSourcesResult?.data?.sources, onChangeSource, sourceId]);


  return { sourceId,
    sourceOptions,
    onChangeSource,
    sourceObject,
    isSourceFetched: fetchPatientWaypointSourcesResult.isFetched };
};


export default useSourceData;
