/* eslint-disable no-nested-ternary */
/*eslint-disable */

import AppleHealthActivityChart from 'pages/Dashboard/components/AppleHealthActivityChart';
import AppleHealthActivitySummaryChart, { DataKey } from 'pages/Dashboard/components/AppleHealthActivitySummaryChart';
import AppleHealthChartsWrapper from 'pages/Dashboard/components/AppleHealthChartsWrapper';
import AppleHealthSleepChart from 'pages/Dashboard/components/AppleHealthSleepChart';
import useActivityData from 'pages/Dashboard/useActivityData';
import useSleepData from 'pages/Dashboard/useSleepData';
import useWaypointData from 'pages/Dashboard/useWaypointData';
import { getChartDateRanges, TimeFrameOptions, waypointBarSizeMap } from 'pages/Dashboard/utils/trackingUtils';
import React, { useEffect, useMemo, useRef } from 'react';
import AppleHealthSleepDialog from 'pages/Dashboard/components/AppleHealthSleepDialog';

import Theme from 'theme';
import trackMixpanelEvent from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

const waypointDataMap = {
    sleepAppleHealth: {
      title: 'Sleep',
      subtitle: 'Time in bed',
      unit: 'hours',
      
    },
    activityDerived: {
      title: 'Total Calories Burned',
      subtitle: 'active + resting',
      icon: 'Whatshot',
      unit: 'calories',
      dataKey: DataKey.TotalCaloriesBurned,
      yAxisDefinition: 'Calories',
    },
    stepCountDerived: {
      title: 'Steps',
      subtitle: '',
      icon: 'directions_walk',
      unit: 'steps',
      dataKey: DataKey.Steps,
      yAxisDefinition: 'Steps',

    },
    daylightExposure: {
      title: 'Time in daylight',
      subtitle: '',
      icon: 'sun',
      unit: 'hrs',
      dataKey: DataKey.DaylightExposure,
      yAxisDefinition: 'Hours',

    },
  };
interface AppleHealthCommonChartProps {
  timeFrame: TimeFrameOptions;
  waypointId:string;
  patientId:string;
  providerId:string;
  title:string;
  subtitle:string;
  type:string;
  openDrawer?:()=>void
  setNumberOfCards?:(value:number)=>void
  yAxisDefinition?:string
  dataKey?:DataKey;
  unit?:string;
  icon?:string
  onChangeTimeFrame: (v: string) => void;
  setCardStatus?: (type:string,v: boolean) => void;
  numberOfCards:number;

  }
function SleepComponent({
  timeFrame,
  waypointId,
  patientId,
  providerId,
  title,
  subtitle,
  onChangeTimeFrame,
  dataKey,
  unit,
  type,
  setCardStatus,
  numberOfCards,

} : AppleHealthCommonChartProps) {
  const {
    averages,
    previousPeriodAverages,
    onChangeSource,
    sourceId,
    sourceOptions, 
    isLoading,
    sourceObject, 
    fetchPatientWaypointTrackingResult } = useSleepData({
    patientId,
    waypointId,
    providerId,
    timeFrame,
  });
  const yAxisProps = getChartDateRanges(timeFrame as TimeFrameOptions) as {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };
  const [isDrawerOpen, setIsDrawerOpen] = React.useState('');

  const closeDrawer = React.useCallback(() => {
    setIsDrawerOpen('');
  }, []);

  const openDrawer = React.useCallback(() => {
    trackMixpanelEvent(
      MixpanelEventName.AH_SLEEP_CHART_VIEW_DETAILS_CLICKED,
      {
        'patient id': patientId ?? '',
      },
      true,
    );
    setIsDrawerOpen('show');
  }, [patientId]);

  const isDataAvailable = React.useMemo(()=>{
    return sourceOptions?.length > 0 || averages?.length > 0;
  }, [sourceOptions, averages])
  useEffect(()=>{
    setCardStatus && setCardStatus(type, isDataAvailable)
  },[isDataAvailable])
  return (
    isDataAvailable ? <><AppleHealthChartsWrapper
      timeFrame={timeFrame}
      title={title}
      subTitle={subtitle}
      isLoading={isLoading}
      headerIcon='nights_stay'
      gridSize={numberOfCards === 3 ? 4 : 6}
      hasMultipleItems
      showTimeFrame
      sourceObject={sourceObject}
      renderChart={(
        <AppleHealthSleepChart
          timeFrame={timeFrame}
          averages={averages}
          previousPeriodAverages={previousPeriodAverages}
          setSourceId={onChangeSource}
          sourceId={sourceId}
          sourceOptions={sourceOptions}
          openDrawer={openDrawer}
        />
    )}
    />
        <AppleHealthSleepDialog
            isOpen={!!isDrawerOpen}
            onClose={closeDrawer}
            timeFrame={timeFrame}
            yAxisProps={yAxisProps}
            onChangeTimeFrame={onChangeTimeFrame}
            sourceId={sourceId}
            isLoading={isLoading}
            fetchPatientWaypointTrackingResult={fetchPatientWaypointTrackingResult as any}
            onChangeSource={onChangeSource}
            sourceOptions={sourceOptions}
          />
    </>
 : null

  );
}
function ActivityComponent({
  patientId,
  providerId,
  waypointId,
  timeFrame,
  title,
  setNumberOfCards,
  subtitle,
  setCardStatus,
  type,
  numberOfCards,
} : AppleHealthCommonChartProps) {
 
  const {
    appleHealthActivityData,
    appleHealthActivityGoals,
    appleHealthActivityAverageData,
    appleHealthActivityPreviousPeriodAverageData,
    appleHealthActivityAvgData,
    barSize,
    sourceOptions,
    sourceId,
    onChangeSource,
    yAxisProps,
    hasStandHours,
    sourceObject,
    isLoading
  } = useActivityData({
    patientId,
    providerId,
    waypointId,
    timeFrame,
  });
  const isDataAvailable = React.useMemo(() => appleHealthActivityData?.length > 0 || sourceOptions?.length > 0, [appleHealthActivityData, sourceOptions]);
  useEffect(()=>{
    setCardStatus && setCardStatus(type, isDataAvailable)
  },[isDataAvailable])
  return isDataAvailable ? (
    <>
      {hasStandHours && (
      <AppleHealthChartsWrapper
        timeFrame={timeFrame}
        title={title}
        subTitle={subtitle}
        headerIcon='snowshoeing'
        gridSize={numberOfCards === 3 ? 4 : 6}
        isLoading={isLoading}
        hasMultipleItems
        showTimeFrame
        sourceObject={sourceObject}
        renderChart={(
          <AppleHealthActivityChart
            chartData={appleHealthActivityData}
            chartGoals={appleHealthActivityGoals}
            yAxisProps={yAxisProps}
            barSize={waypointBarSizeMap[`${timeFrame}-${4}`]}
            averages={appleHealthActivityAverageData}
            previousPeriodAverages={appleHealthActivityPreviousPeriodAverageData}
            timeFrame={timeFrame}
            
          />
        )}
      />
      )}
      {!hasStandHours && (
      <AppleHealthChartsWrapper
        timeFrame={timeFrame}
        title={title}
        subTitle={subtitle}
        gridSize={numberOfCards === 3 ? 4 : 6}
        headerIcon='snowshoeing'
        hasMultipleItems
        showTimeFrame
        sourceObject={sourceObject}
        isLoading={isLoading}
        renderChart={(
          <AppleHealthActivitySummaryChart
            chartData={appleHealthActivityData}
            dataKey={sourceObject?.sourcePlatform === 'ios'?  DataKey.ActiveEnergyBurned : DataKey.TotalCaloriesBurned}
            units='kCal'
            average={appleHealthActivityAvgData}
            yAxisProps={yAxisProps}
            yAxisDefinition='Calories'
            barSize={waypointBarSizeMap[`${timeFrame}-${4}`]}
            sourceId={sourceId}
            sourceOptions={sourceOptions}
            setSourceId={onChangeSource}
            
          />
        )}
      />
      )}
    </>

  ) : null;
}

function SummaryChart({
  patientId,
  providerId,
  waypointId,
  timeFrame,
  title,
  subtitle,
  yAxisDefinition ,
  dataKey = DataKey.Steps,
  unit = "Steps",
  icon = "",
  type,
  setCardStatus,
  numberOfCards
} : AppleHealthCommonChartProps) {
  const {
    sourceOptions,
    sourceId,
    onChangeSource,
    chartData,
    average,
    previousPeriodAverage,
    yAxisProps,
    isLoading = true,
    sourceObject,
  } = useWaypointData({
    patientId,
    providerId,
    waypointId,
    timeFrame,
  });
  const isData = useMemo(() => sourceOptions?.length>0 || chartData?.length>0, [sourceOptions, chartData]);
  useEffect(()=>{
    setCardStatus && setCardStatus(type, isData)
  },[isData])
  return isData ? (
    <AppleHealthChartsWrapper
      timeFrame={timeFrame}
      title={title}
      subTitle={subtitle}
      gridSize={numberOfCards === 3 ? 4 : 6}
      hasMultipleItems
      isLoading={isLoading}
      headerIcon={"directions_walk"}
      key={waypointId}
      sourceObject={sourceObject}
      renderChart={(
        <AppleHealthActivitySummaryChart
          chartData={chartData}
          dataKey={dataKey}
          units={unit ?? ""}
          yAxisProps={yAxisProps}
          yAxisDefinition={yAxisDefinition ?? ""}
          previousPeriodAverage={previousPeriodAverage}
          showAverages={false}
          barSize={waypointBarSizeMap[`${timeFrame}-${4}`]}
          barColor={Theme.custom.colors.lightTertiaryMain}
          sourceId={sourceId}
          sourceOptions={sourceOptions}
          setSourceId={onChangeSource}
        />
)}
    />
  ) : null;
}

export default function AppleHealthCommonChart(props: AppleHealthCommonChartProps) {
  const {
    timeFrame,
    patientId,
    providerId,
    type,
    waypointId,
    openDrawer,
    title, 
    subtitle,
    onChangeTimeFrame,
    setCardStatus,
    numberOfCards

  } = props;
  
  //@ts-ignore
  const waypointData = waypointDataMap[type as keyof waypointDataMap];

  return <React.Fragment key = {waypointId}> 
    {type === 'sleepDerived' ? (

      <SleepComponent
        timeFrame={timeFrame}
        waypointId={waypointId}
        patientId={patientId}
        providerId={providerId}
        openDrawer={openDrawer}
        title={title}
        subtitle={subtitle}
        key={waypointId}
        {...waypointData}
        onChangeTimeFrame={onChangeTimeFrame}
        type={type}
        setCardStatus={setCardStatus}
        numberOfCards={numberOfCards}
      />
    ) : type === 'stepCountDerived'
      ? (
        <SummaryChart
          timeFrame={timeFrame}
          waypointId={waypointId}
          patientId={patientId}
          providerId={providerId}
          title={title}
          subtitle={subtitle}
          key={waypointId}
          {...waypointData}   
          type={type}
          setCardStatus={setCardStatus}
          numberOfCards={numberOfCards}
        />
      ) : type === 'activityDerived' ? (
        <ActivityComponent
          timeFrame={timeFrame}
          waypointId={waypointId}
          patientId={patientId}
          providerId={providerId}
          title={title}
          subtitle={subtitle}
          key={waypointId}
          {...waypointData}
          type={type}
          setCardStatus={setCardStatus}
          numberOfCards={numberOfCards}
        />

      ) : (
        <SummaryChart
          timeFrame={timeFrame}
          waypointId={waypointId}
          patientId={patientId}
          providerId={providerId}
          title={title}
          subtitle={subtitle}
          key={waypointId}
          type={type}
          {...waypointData}
          setCardStatus={setCardStatus}
          numberOfCards={numberOfCards}
        />
      )
  }
  </React.Fragment>
}
