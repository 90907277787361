import React, { useState } from 'react';
import { TextField, Checkbox, Autocomplete, Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useHttp } from 'hooks/use-fetch';
import ContentLoader from 'components/Common/ContentLoader';
import { chiefAdminUrls } from 'utils/apiUrls';
import { CategoryType } from 'pages/Dashboard/types/whoiam.types';
import { fetchCategoriesByType, fetchPracticeCategoriesByType } from 'pages/AdminPortal/services/practicebundles.services';
import { UpdatedBundle, BundleAction, UpdatePracticeBundlePayload } from 'pages/AdminPortal/types';

type UpdateBundleCallback = React.Dispatch<React.SetStateAction<Record<string, UpdatedBundle>>>;

function UpdatePracticeBundles({
  practiceId,
  setUpdatedPracticeBundle,
}: {
  practiceId: string;
  setUpdatedPracticeBundle: UpdateBundleCallback;
}) {
  const { http } = useHttp();

  const [ratingScaleBundleId, setRatingScaleBundleId] = useState('');
  const [personalityProfileBundleId, setPersonalityProfileBundleId] = useState('');
  const [defaultAssociatedRatingScales, setDefaultAssociatedRatingScales] = useState(
    {} as Record<string, boolean>,
  );
  const [defaultAssociatedPersonalityProfiles, setDefaultAssociatedPersonalityProfiles] = useState(
    {} as Record<string, boolean>,
  );
  const [selectedRatingScaleOptions, setSelectedRatingScaleOptions] = useState(
    [] as { title: string; value: string }[],
  );
  const [ratingScaleOptions, setRatingScaleOptions] = useState(
    [] as { title: string; value: string }[],
  );
  const [selectedPersonalityProfileOptions, setSelectedPersonalityProfileOptions] = useState(
    [] as { title: string; value: string }[],
  );
  const [personalityProfileOptions, setPersonalityProfileOptions] = useState(
    [] as { title: string; value: string }[],
  );

  const { isFetching: isFetchingRatingScales } = useQuery(
    [chiefAdminUrls.listCategories.queryUrl, CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE],
    {
      queryFn: fetchCategoriesByType(http.get, CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE),
      onSuccess(data) {
        setRatingScaleOptions(
          data.categories.map((category) => ({
            title: category.description,
            value: category.name,
          })),
        );
      },
      retry: 0,
    },
  );

  const { isFetching: isFetchingPracticeRatingScale } = useQuery(
    [
      chiefAdminUrls.listCategories.queryUrl,
      practiceId,
      CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE,
    ],
    {
      queryFn: fetchPracticeCategoriesByType(
        http.get,
        practiceId,
        CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE,
      ),
      enabled: !!practiceId,
      onSuccess(data) {
        const associatedRatingScales = {} as Record<string, boolean>;
        const bundle = data?.bundles?.[0] ?? {};
        const bundleId = bundle.bundleId ?? '';
        setRatingScaleBundleId(bundleId);
        setSelectedRatingScaleOptions(
          bundle.categories.map((category) => {
            associatedRatingScales[category.name] = true;
            return {
              title: category.description,
              value: category.name,
            };
          }),
        );
        setDefaultAssociatedRatingScales(associatedRatingScales);
      },
      retry: 0,
    },
  );

  const { isFetching: isFetchingPersonalityProfiles } = useQuery(
    [chiefAdminUrls.listCategories.queryUrl, CategoryType.WIM_CATEGORY_TYPE_PERSONALITY_PROFILE],
    {
      queryFn: fetchCategoriesByType(http.get, CategoryType.WIM_CATEGORY_TYPE_PERSONALITY_PROFILE),
      onSuccess(data) {
        setPersonalityProfileOptions(
          data.categories.map((category) => ({
            title: category.description,
            value: category.name,
          })),
        );
      },
      retry: 0,
    },
  );

  const { isFetching: isFetchingPracticePersonalityProfiles } = useQuery(
    [
      chiefAdminUrls.listCategories.queryUrl,
      practiceId,
      CategoryType.WIM_CATEGORY_TYPE_PERSONALITY_PROFILE,
    ],
    {
      queryFn: fetchPracticeCategoriesByType(
        http.get,
        practiceId,
        CategoryType.WIM_CATEGORY_TYPE_PERSONALITY_PROFILE,
      ),
      enabled: !!practiceId,
      onSuccess(data) {
        const associatedPersonalityProfiles = {} as Record<string, boolean>;
        const bundle = data?.bundles?.[0] ?? {};
        const bundleId = bundle.bundleId ?? '';
        setPersonalityProfileBundleId(bundleId);
        setSelectedPersonalityProfileOptions(
          data.bundles[0].categories.map((category) => {
            associatedPersonalityProfiles[category.name] = true;
            return {
              title: category.description,
              value: category.name,
            };
          }),
        );
        setDefaultAssociatedPersonalityProfiles(associatedPersonalityProfiles);
      },
      retry: 0,
    },
  );

  const isFetching = React.useMemo(
    () => isFetchingRatingScales
      || isFetchingPersonalityProfiles
      || isFetchingPracticeRatingScale
      || isFetchingPracticePersonalityProfiles,
    [
      isFetchingRatingScales,
      isFetchingPersonalityProfiles,
      isFetchingPracticeRatingScale,
      isFetchingPracticePersonalityProfiles,
    ],
  );

  const onChange = (categoryType: CategoryType, newValue: { title: string; value: string }[]) => {
    if (categoryType === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE) {
      setSelectedRatingScaleOptions(newValue);
    }
    else {
      setSelectedPersonalityProfileOptions(newValue);
    }
    const bundleId = categoryType === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE
      ? ratingScaleBundleId
      : personalityProfileBundleId;
    const defaultAssociated = categoryType === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE
      ? defaultAssociatedRatingScales
      : defaultAssociatedPersonalityProfiles;
    if (newValue.length === 0) {
      setUpdatedPracticeBundle((prev) => ({
        ...prev,
        [categoryType]: {
          bundleId,
          payload: {} as UpdatePracticeBundlePayload,
          deleteAll: true,
        },
      }));
      return;
    }
    if (bundleId === '') {
      const toAssociate = newValue.map((value) => value.value);
      setUpdatedPracticeBundle((prev) => ({
        ...prev,
        [categoryType]: {
          bundleId,
          payload: {
            bundle_items: toAssociate.map((item) => ({
              item_id: item,
              action: BundleAction.ACTION_TYPE_ADD_BUNDLE_ITEM,
            })),
          },
        },
      }));
    }
    else {
      const toAssociate = [] as string[];
      const updatedMap = { ...defaultAssociated } as Record<string, boolean>;
      newValue.forEach((value) => {
        if (updatedMap[value.value]) {
          delete updatedMap[value.value];
        }
        else {
          toAssociate.push(value.value);
        }
      });
      const toDisassociate = Object.keys(updatedMap);
      setUpdatedPracticeBundle((prev) => ({
        ...prev,
        [categoryType]: {
          bundleId,
          payload: {
            bundle_items: [
              ...toAssociate.map((item) => ({
                item_id: item,
                action: BundleAction.ACTION_TYPE_ADD_BUNDLE_ITEM,
              })),
              ...toDisassociate.map((item) => ({
                item_id: item,
                action: BundleAction.ACTION_TYPE_REMOVE_BUNDLE_ITEM,
              })),
            ],
          },
        },
      }));
    }
  };

  return (
    <ContentLoader isFetching={isFetching} isError={false} minHeight={100}>
      <Box>
        <Typography variant='body1' mb={2}>
          Select rating scales to add to the practice
          {' '}
          {!ratingScaleBundleId ? '(Default)' : ''}
        </Typography>
        <Autocomplete
          multiple
          options={ratingScaleOptions}
          getOptionLabel={(option) => option.title}
          loading={isFetchingRatingScales || isFetchingPracticeRatingScale}
          value={selectedRatingScaleOptions}
          onChange={(__, newValue) => {
            onChange(CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE, newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Rating scales'
              placeholder='Search rating scale...'
            />
          )}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ mr: 1 }} checked={selected} />
              {option.title}
            </li>
          )}
        />
        <Typography variant='body1' mb={2} mt={3}>
          Select personality profiles to add to the practice
          {' '}
          {!personalityProfileBundleId ? '(Default)' : ''}
        </Typography>
        <Autocomplete
          multiple
          options={personalityProfileOptions}
          getOptionLabel={(option) => option.title}
          value={selectedPersonalityProfileOptions}
          onChange={(__, newValue) => {
            onChange(CategoryType.WIM_CATEGORY_TYPE_PERSONALITY_PROFILE, newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Personality profiles'
              placeholder='Search personality profile...'
            />
          )}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ mr: 1 }} checked={selected} />
              {option.title}
            </li>
          )}
        />
      </Box>
    </ContentLoader>
  );
}

export default UpdatePracticeBundles;
