import {
  PatientDemographicInfoPayload,
  PatientFormData,
  PatientInvitesPayload,
  ResendInvitePayload,
} from 'pages/Dashboard/types';
import {
  AddPatientPayload,
  PatientListResponse,
  PatientsCountResponse,
  PatientStatusReponse,
} from 'pages/Dashboard/types/patient.types';
import { QueryFunctionContext } from 'react-query';
import { patientUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const addPatient = (
  method: HttpType['post'],
  providerId: string,
  payload: PatientFormData,
) => method(patientUrls.createPatient.apiUrls(providerId).create, payload);

export const createPatient = (
  method: HttpType['post'],
  providerId: string,
  payload: PatientDemographicInfoPayload,
) => method(patientUrls.createPatient.apiUrls(providerId).create, payload);

export const invitePatients = (
  method: HttpType['post'],
  providerId: string,
  payload: PatientInvitesPayload,
) => method(patientUrls.invitePatients.apiUrls(providerId).create, payload);

export const fetchPatientList = (method: HttpType['get'], isNewListEnabled = false) => function async({ queryKey }: QueryFunctionContext): Promise<PatientListResponse> {
  const [
    ,
    providerId,
    status,
    searchQuery,
    sortDirection,
    sortField,
    offset,
    limit,
    providerIdFilter,
  ] = queryKey;
  const apiUrl = isNewListEnabled
    ? patientUrls.listPatientsNew.apiUrls
    : patientUrls.listPatients.apiUrls;
  return method(
    apiUrl(
        providerId as string,
        status as string,
        searchQuery as string,
        sortDirection as 'asc' | 'desc',
        sortField as string,
        offset as number,
        limit as number,
        providerIdFilter as string,
    ).list,
  );
};

export const fetchPatientsCount = (method: HttpType['get'], isNewListEnabled = false) => function async({ queryKey }: QueryFunctionContext): Promise<PatientsCountResponse> {
  const [, providerId, searchQuery, providerIdFilter] = queryKey;
  const apiUrl = isNewListEnabled
    ? patientUrls.patientsCountNew.apiUrls
    : patientUrls.patientsCount.apiUrls;
  return method(
    apiUrl(providerId as string, searchQuery as string, providerIdFilter as string).count,
  );
};

export const resendPatientInvite = (
  method: HttpType['post'],
  providerId: string,
  payload: ResendInvitePayload,
) => method(patientUrls.resendPatientInvite.apiUrls(providerId).create, payload);

export const fetchPatientDetails = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<AddPatientPayload> {
  const [, providerId, patientId] = queryKey;

  return method(
    patientUrls.patientDetails.apiUrls(providerId as string, patientId as string).details,
  );
};

export const fetchPatientHealthRecordStatus = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PatientStatusReponse> {
  const [, providerId, patientId] = queryKey;

  return method(
    patientUrls.patientHealthRecordStatus.apiUrls(providerId as string, patientId as string).status,
  );
};
