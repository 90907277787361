import { add, set } from 'date-fns';
import {
  MoodTrackingAverageResponse,
  MoodTrackingListResponse,
} from 'pages/Dashboard/types/moods.types';
import { ListWaypointDetails, MoodFactorDomainsResponse, MostLoggedMetricsResponse, SomaticScoreResponse, StroopTimeSeriesAPIResponse, WaypointSourcesAPIResponse, WaypointTrackingAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import { getDuration, getTimeFrameInterval } from 'pages/Dashboard/utils/trackingUtils';
import { QueryFunctionContext } from 'react-query';
import { gamesUrls, trackingUrls } from 'utils/apiUrls';
import { formatToBrowserDate } from 'utils/dateUtils';
import { HttpType } from 'utils/http';

const dateToGMTString = (date: Date, addDay?: boolean): string => {
  let dateWithCurrentTime = set(new Date(date), {
    hours: new Date().getHours(),
    minutes: new Date().getMinutes(),
    seconds: new Date().getSeconds(),
  });
  if (addDay) {
    dateWithCurrentTime = add(dateWithCurrentTime, { days: 1 });
  }
  const isoString = new Date(dateWithCurrentTime).toISOString();
  return isoString.split('T')[0];
};

// eslint-disable-next-line max-len
export const fetchMoodAverage = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MoodTrackingAverageResponse> {
  const [, providerId, patientId, selectTimeFrame, identifier] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  if (identifier === 'current') {
    const { currInterval } = intervals;
    return method(
      trackingUrls.moodAverage.apiUrls(
        providerId as string,
        patientId as string,
        dateToGMTString(new Date(currInterval.start)),
        dateToGMTString(new Date(currInterval.end), true),
      ).list,
    );
  }

  const { previousInterval } = intervals;
  return method(
    trackingUrls.moodAverage.apiUrls(
      providerId as string,
      patientId as string,
      previousInterval.start,
      previousInterval.end,
    ).list,
  );
};

export const fetchMoodChart = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MoodTrackingListResponse> {
  const [, providerId, patientId, selectTimeFrame, mood] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const { currInterval } = intervals;

  return method(
    trackingUrls.moodList.apiUrls(
      providerId as string,
      patientId as string,
      dateToGMTString(new Date(currInterval.start)),
      dateToGMTString(new Date(currInterval.end), true),
      mood as string,
    ).list,
  );
};

export const fetchWaypointsTracking = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<WaypointTrackingAPIResponse> {
  const [, providerId, patientId, selectTimeFrame, identifier] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  if (identifier === 'current') {
    const { currInterval } = intervals;
    return method(
      trackingUrls.waypointAverage.apiUrls(
        providerId as string,
        patientId as string,
        currInterval.start,
        formatToBrowserDate(add(new Date(), { days: 1 })),
      ).list,
    );
  }

  const { previousInterval } = intervals;
  return method(
    trackingUrls.waypointAverage.apiUrls(
      providerId as string,
      patientId as string,
      previousInterval.start,
      previousInterval.end,
    ).list,
  );
};

export const fetchWaypointTracking = (method: HttpType['get'], isSleepWaypoint?: boolean, isPrevious = false) => function async({ queryKey }: QueryFunctionContext): Promise<WaypointTrackingAPIResponse | ListWaypointDetails> {
  const [, providerId, patientId, waypointId, selectTimeFrame, sourceId] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const duration = getDuration(selectTimeFrame as string);
  const { currInterval, previousInterval } = intervals;
  const interval = isPrevious ? previousInterval : currInterval;
  const url = isSleepWaypoint
    ? trackingUrls.waypointDetails.apiUrls
    : trackingUrls.waypoint.apiUrls;
  return method(
    url(
      providerId as string,
      patientId as string,
      waypointId as string,
      interval?.start,
      interval?.end,
      duration,
      sourceId as string,
    ).list,
  );
};

export const fetchGamesTracking = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<StroopTimeSeriesAPIResponse> {
  const [, providerId, patientId, selectTimeFrame] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const duration = getDuration(selectTimeFrame as string);
  const { currInterval } = intervals;
  return method(
    gamesUrls.stroopTimeSeries.apiUrls(
      providerId as string,
      patientId as string,
      currInterval.start,
      formatToBrowserDate(add(new Date(), { days: 1 })),
      duration,
    ).list,
  );
};

export const fetchWaypointSources = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<WaypointSourcesAPIResponse> {
  const [, providerId, patientId, waypointId] = queryKey;
  return method(
    trackingUrls.waypointSources.apiUrls(
      providerId as string,
      patientId as string,
      waypointId as string,
    ).list,
  );
};

export const fetchMostLoggedMetrics = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MostLoggedMetricsResponse | MoodFactorDomainsResponse> {
  const [, providerId, patientId, selectTimeFrame, entity, entityKey] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const { currInterval } = intervals;

  return method(
    trackingUrls.mostLoggedMetrics.apiUrls(
        providerId as string,
        patientId as string,
        dateToGMTString(new Date(currInterval.start)),
        dateToGMTString(new Date(currInterval.end), true),
        entity as string,
        (entityKey as string),
    ).list,
  );
};

export const fetchSomaticScore = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<SomaticScoreResponse> {
  const [, providerId, patientId, selectTimeFrame] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const duration = getDuration(selectTimeFrame as string);
  const { currInterval } = intervals;
  const interval = currInterval;
  return method(
    trackingUrls.somaticScore.apiUrls(
      providerId as string,
      patientId as string,
      interval.start,
      formatToBrowserDate(add(new Date(), { days: 1 })),
      duration,
    ).list,
  );
};
