import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProviderRequestList from 'pages/AdminPortal/Tabs/ProviderRequestList/ProviderRequestList';
import PracticeList from 'pages/AdminPortal/Tabs/Practice/PracticeList';
import { Tabs } from 'components/Common/TabComponent';
import AuditLogs from 'pages/AdminPortal/Tabs/AuditLogs/AuditLogs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <Box
      width='100%'
      hidden={value !== index}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default function AdminPortal() {
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue: string | number) => {
    setValue(newValue as number);
  };

  const tabContext = [
    { header: 'PRACTICE LIST' },
    { header: 'PROVIDER REQUESTS' },
    { header: 'AUDIT LOGS' },
  ];

  return (
    <Box sx={{ bgcolor: 'background.paper', p: 3 }}>
      <Tabs
        value={value}
        setValue={handleChange}
        tabContext={tabContext}
        variant='standard'
        activeStyles={{
          backgroundColor: '#FFF',
          maxHeight: 24,
        }}
      />
      <TabPanel value={value} index={0}>
        <PracticeList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProviderRequestList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AuditLogs />
      </TabPanel>
    </Box>
  );
}
