import React, { useState } from 'react';
import { TextField, Checkbox, Autocomplete, Box, Typography } from '@mui/material';
import { fetchPracticeProviderList } from 'pages/Dashboard/services/addpatient.services';
import { useQuery } from 'react-query';
import { useHttp } from 'hooks/use-fetch';
import ContentLoader from 'components/Common/ContentLoader';
import { providers } from 'utils/apiUrls';
import { fetchProvidersAssociationsList } from 'pages/Dashboard/services/provider.services';
import { Designation, EProfessions } from 'types/Entitites';

function Associate({
  practiceId,
  providerId,
  setProviders,
}: {
  practiceId: string;
  providerId: string;
  setProviders?: ({
    toAssociate,
    toDisassociate,
  }: {
    toAssociate: string[];
    toDisassociate: string[];
  }) => void;
}) {
  const { http } = useHttp();

  const [selectedOptions, setSelectedOptions] = useState(
    [] as { title: string; value: string; designation: string }[],
  );
  const [options, setOptions] = useState(
    [] as { title: string; value: string; designation: string }[],
  );
  const [defaultAssociatedProviders, setDefaultAssociatedProviders] = useState(
    {} as Record<string, boolean>,
  );

  const { isFetching } = useQuery('practices-list-for-association', {
    queryFn: fetchPracticeProviderList(http.get, practiceId),
    enabled: !!practiceId,
    onSuccess(data) {
      const restOfProviders = data.providers.reduce((acc, provider) => {
        if (provider.id !== providerId) {
          acc.push({
            value: provider.id,
            title: `${provider.firstName} ${provider.lastName}`,
            designation: provider.designation,
          });
        }
        return acc;
      }, [] as { title: string, value: string, designation: string }[]);
      setOptions(restOfProviders);
    },
  });

  const { isFetching: isFetchingAssociations } = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'dashboard'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
      onSuccess(data) {
        const associatedProviders = {} as Record<string, boolean>;
        const selectedProviders = data.providers.map((provider) => {
          associatedProviders[provider.id] = true;
          return ({
            value: provider.id,
            title: `${provider.firstName} ${provider.lastName}`,
            designation: provider.designation,
          });
        });
        setSelectedOptions(selectedProviders);
        setDefaultAssociatedProviders(associatedProviders);
      },
    },
  );

  return (
    <ContentLoader
      isFetching={isFetching || isFetchingAssociations}
      isError={false}
      minHeight={100}
    >
      <Box>
        <Typography variant='body1' mb={2}>
          Select providers to associate
        </Typography>
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option.title}
          value={selectedOptions}
          disableCloseOnSelect
          onChange={(__, newValue) => {
            const toAssociate = [] as string[];
            const updatedMap = { ...defaultAssociatedProviders } as Record<string, boolean>;
            newValue.forEach((value) => {
              if (updatedMap[value.value]) {
                delete updatedMap[value.value];
              }
              else {
                toAssociate.push(value.value);
              }
            });
            const toDisassociate = Object.keys(updatedMap);
            setProviders && setProviders({
              toAssociate,
              toDisassociate,
            });
            setSelectedOptions(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Providers'
              placeholder='Search providers...'
            />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ mr: 1 }} checked={selected} />
              {option.title}
              {' '}
              (
              {EProfessions[option.designation as Designation]}
              )
            </li>
          )}
        />
      </Box>
    </ContentLoader>
  );
}

export default Associate;
