import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import PracticeInfoForm from 'pages/Dashboard/components/PracticeInfoForm';
import PatientInfoForm from 'pages/Dashboard/components/PatientInfoForm';
import {
  PatientDemographicFormConsent,
  PatientInviteFormConsent,
  SMSConsent,
} from 'pages/Dashboard/components/AddPatientConsents';
import { PatientForm } from 'pages/Dashboard/types';
import { CircularProgress, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

interface IProps {
  onSubmit: SubmitHandler<PatientForm>;
  patientFormDemographic: boolean;
  setPatientFormDemographic: (val: boolean) => void;
  isLoading: boolean;
}

export default function AddNewPatientForm({
  onSubmit,
  patientFormDemographic,
  setPatientFormDemographic,
  isLoading,
}: IProps) {
  const { handleSubmit } = useFormContext();

  function getButtonContent() {
    if (isLoading) {
      return <CircularProgress color='primary' size={30} />;
    }
    return <Typography variant='h6'>Send invite</Typography>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <PracticeInfoForm />
        </Grid>
        <Grid item>
          <PatientInfoForm
            isDemographicForm={patientFormDemographic}
            setIsDemographicForm={setPatientFormDemographic}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 6, mb: 2 }}>
        <Button
          color='success'
          variant='contained'
          type='submit'
          size='large'
          disabled={isLoading}
          sx={{ width: 300 }}
          startIcon={<SendIcon />}
        >
          {getButtonContent()}
        </Button>
      </Box>
      {!patientFormDemographic && <SMSConsent />}
      <Box mt={2}>
        {patientFormDemographic ? <PatientDemographicFormConsent /> : <PatientInviteFormConsent />}
      </Box>
    </form>
  );
}
