import { useHttp } from 'hooks/use-fetch';
import { fetchWaypointTracking } from 'pages/Dashboard/services/tracking.services';
import { ListWaypointDetails } from 'pages/Dashboard/types/waypoints.types';
import useSourceData from 'pages/Dashboard/useSourceData';
import React from 'react';
import { useQuery } from 'react-query';
import { trackingUrls } from 'utils/apiUrls';

interface SleepDataProps {
    waypointId: string;
    providerId:string;
    patientId:string;
    timeFrame:string;
}
const useSleepData = ({
  waypointId,
  providerId,
  patientId,
  timeFrame,
} : SleepDataProps) => {
  const { http } = useHttp();
  const { sourceOptions, sourceId, onChangeSource, sourceObject } = useSourceData({
    providerId,
    waypointId,
    patientId,
  });

  const fetchPatientWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}-${sourceId}-${timeFrame}`,
      providerId,
      patientId,
      waypointId,
      timeFrame,
      sourceId,
    ],
    {
      queryFn: fetchWaypointTracking(http.get, true),
      enabled: !!providerId && !!patientId && !!sourceId && !!waypointId,
    },
  );

  const appleHealthSleepData = React.useMemo(() => {
    const data = (
      fetchPatientWaypointTrackingResult?.data as ListWaypointDetails
    )?.categoryAvgData || [];
    return data;
  }, [fetchPatientWaypointTrackingResult?.data]);
  const appleHealthSleepPreviousPeriodAvgData = React.useMemo(() => {
    const data = (fetchPatientWaypointTrackingResult?.data as ListWaypointDetails)
      ?.previousIntervalCategoryAvgData || [];
    return data;
  }, [fetchPatientWaypointTrackingResult?.data]);

  return {
    sourceOptions,
    averages: appleHealthSleepData,
    sourceId,
    onChangeSource,
    timeFrame,
    previousPeriodAverages: appleHealthSleepPreviousPeriodAvgData,
    isLoading: fetchPatientWaypointTrackingResult.isLoading,
    fetchPatientWaypointTrackingResult,
    sourceObject,
  };
};

export default useSleepData;
