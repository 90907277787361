import * as React from 'react';
import { TableCell, Typography } from '@mui/material';
import { NotSpecified } from 'utils/constants';
import { PatientPersonalityTestResultTextProps } from 'pages/Dashboard/types/whoiam.types';


export default function QualityOfLifeText({
  result,
  isSummary = false,
}: PatientPersonalityTestResultTextProps): JSX.Element {
  const qualityOfLifeResult = result ? `${result?.score} out of ${result?.totalScore}` : NotSpecified;
  const Container = isSummary ? TableCell : React.Fragment;
  return (
    <Container>
      <Typography padding={1.25}>{qualityOfLifeResult}</Typography>
    </Container>
  );
}
