import { ArrowDropDown, ArrowDropUp, ArrowRightAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useHttp } from 'hooks/use-fetch';
import { fetchMoodAverage } from 'pages/Dashboard/services/tracking.services';
import {
  fetchWhoIamCategories,
  fetchWhoIamCategoryResults,
} from 'pages/Dashboard/services/whoiam.services';
import { Mood, MoodColorType, MoodV2 } from 'pages/Dashboard/types/moods.types';
import { CategoryType } from 'pages/Dashboard/types/whoiam.types';
import {
  defaultMoodTracking,
  getGaugeColor,
  getGaugeColorV2,
  getGaugeData,
  getGaugeDataV2,
  getMoodOptions,
  getMoodValueLabel,
  TimeFrameOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import React from 'react';
import { useQueries, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { medicationsUrls, trackingUrls, whoIam } from 'utils/apiUrls';
import { Logger } from 'utils/logger';
import * as spaUrls from 'utils/spaUrls';
import PatientTrackingSummary from 'pages/Dashboard/PatientTrackingSummary';
import GaugeChartSmall from 'pages/Dashboard/components/GaugeChartSmall';
import GaugeChartSmallV2 from 'pages/Dashboard/components/GaugeChartSmallV2';
import PatientRatingScalesSummary from 'pages/Dashboard/components/PatientRatingScalesSummary';
import Theme from 'theme';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { fetchAllMedicationCount } from 'pages/Dashboard/services/medications.services';

const sentenceCase = (str: string) => str
  .toLowerCase()
  .split('_')
  .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
  .join(' ');

const getChangeDirection = (isPositive: boolean, color: string) => {
  if (isPositive) {
    return <ArrowDropUp sx={{ mt: '2px', color: `${color}`, ml: -1 }} />;
  }
  else {
    return <ArrowDropDown sx={{ mt: '2px', color: `${color}`, ml: -1 }} />;
  }
};
const MoodColors: MoodColorType = {
  Happiness: { positive: 'green', negative: 'red' },
  Motivation: { positive: 'green', negative: 'red' },
  Anxiousness: { positive: 'red', negative: 'green' },
  Pleasantness: { positive: 'green', negative: 'red' },
  Energy: { positive: 'green', negative: 'red' },
};
type CardData = {
  label: string;
  mood: string;
  currentValue: number;
  previousValue: number;
};

function Comparison({
  moodDifference,
  cardData,
  isComparisonPossible,
  isNewVersionEnabled,
}: {
  moodDifference: number;
  cardData: CardData;
  isComparisonPossible: boolean;
  isNewVersionEnabled: boolean;
}) {
  const isPositive = moodDifference > 0;
  const isZero = moodDifference === 0;
  if (isZero) {
    return (
      <Typography textAlign='center' color='gray' fontWeight='400'>
        -
      </Typography>
    );
  }
  const color = isPositive
    ? MoodColors[cardData.label].positive
    : MoodColors[cardData.label].negative;
  if (!isComparisonPossible) {
    return null;
  }
  return isNewVersionEnabled ? (
    <Box display='flex' flexDirection='column' alignItems='center' ml={1}>
      {getChangeDirection(isPositive, color)}
      <Typography variant='body1' color={Theme.custom.colors.lightTextSecondary} textAlign='center'>
        {getMoodValueLabel(cardData.mood as MoodV2, cardData.previousValue)}
        <Typography
          variant='subtitle2'
          color={Theme.custom.colors.lightTextSecondary}
          textAlign='center'
        >
          (prior 30 days)
        </Typography>
      </Typography>
    </Box>
  ) : (
    <>
      {getChangeDirection(isPositive, color)}
      <Typography textAlign='center' color={color} fontWeight='500'>
        {isPositive ? `${moodDifference}` : moodDifference}
      </Typography>
    </>
  );
}
function ExpandedRowView({ id, patient }: { id: string; patient: PatientObj }) {
  const { http, providerId, isNewVersionEnabled } = useHttp();
  const navigate = useNavigate();

  const isBehavioralDataPresent = patient.behavioralDataStatus === 'PRESENT';
  const noDataMessage = isBehavioralDataPresent ? 'No data for this timeframe' : 'No data currently exists';

  const currentMoodAverage = useQuery(
    [
      `${trackingUrls.moodAverage.queryUrl}`,
      providerId,
      id,
      TimeFrameOptions.LAST_30_DAYS,
      'current',
    ],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: !!id && isBehavioralDataPresent,
    },
  );

  const previousMoodAverage = useQuery(
    [
      `${trackingUrls.moodAverage.queryUrl}`,
      providerId,
      id,
      TimeFrameOptions.LAST_30_DAYS,
      'previous',
    ],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: !currentMoodAverage.isFetching && !!id && isBehavioralDataPresent,
    },
  );
  const whoIAmCategories = useQuery([whoIam.categories.queryUrl, providerId, id], {
    queryFn: fetchWhoIamCategories(http.get),
    enabled: !!isBehavioralDataPresent,
  });
  const onError = (message: string) => {
    Logger.error(message);
  };
  const currentMoodData = currentMoodAverage.data ?? defaultMoodTracking;
  const previousMoodData = previousMoodAverage.data ?? defaultMoodTracking;
  const isComparisonPossible = previousMoodData?.moods?.length > 0;
  const isEnoughDataPresent = currentMoodData?.moods?.length > 0;
  const moodOptions = getMoodOptions(
    currentMoodData.moods,
    previousMoodData.moods,
    isNewVersionEnabled,
  );

  const applicableRatingScales = React.useMemo(
    () => whoIAmCategories?.data?.categories?.filter?.(
      (category) => category.type === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE,
    ),
    [whoIAmCategories],
  );

  const personalityProfileCategories = whoIAmCategories?.data?.categories?.filter?.(
    (category, index) => category.type === CategoryType.WIM_CATEGORY_TYPE_PERSONALITY_PROFILE,
  );
  const personalityProfileResultQueries = personalityProfileCategories?.map((category, index) => ({
    queryKey: [`${whoIam.categoryResults.queryUrl}-${category.id}`, providerId, id, category.id],
    queryFn: fetchWhoIamCategoryResults(http.get),
    enabled: !!category.id,
  })) ?? [];
  const personalityProfileCategoryResults = useQueries(personalityProfileResultQueries);
  const personalityProfileResults = personalityProfileCategoryResults.map(
    (response) => response.data,
  );
  const scaleResultQueries = applicableRatingScales?.map?.((category) => ({
    queryKey: [`${whoIam.categoryResults.queryUrl}-${category.id}`, providerId, id, category.id],
    queryFn: fetchWhoIamCategoryResults(http.get),
    enabled: !!category.id,
  })) ?? [];

  const medicationsCountQuery = useQuery({
    queryKey: [medicationsUrls.counts.queryUrl, providerId, id],
    queryFn: fetchAllMedicationCount(http.get),
  });

  const scaleResultResponses = useQueries(scaleResultQueries);
  const medicationsCount = React.useMemo(
    () => medicationsCountQuery?.data?.summary?.summary
    || ([] as { name: string; count: number }[]),
    [medicationsCountQuery?.data?.summary?.summary],
  );
  const scaleResults = scaleResultResponses.map((response) => response.data);
  const isScaleDataPresent = scaleResults.some((scaleResult) => scaleResult?.results?.length);
  const isPersonalityProfileDataPresent = personalityProfileCategoryResults.some(
    (personalityResults) => personalityResults.data?.results?.length,
  );
  const isRatingScaleLoading = whoIAmCategories.isLoading
    || scaleResultResponses.some((response) => response.isLoading)
    || personalityProfileCategoryResults.some((response) => response.isLoading);
  const navigateToPatientDetailsPage = () => {
    navigate(spaUrls.mainNav.patientDetail(id));
    trackMixpanelEvent(MixpanelEventName.VIEW_MORE_BUTTON_CLICK, { patient_id: id });
  };

  const isMoodLoading = currentMoodAverage.isLoading || previousMoodAverage.isLoading;
  const activityText = isNewVersionEnabled ? 'Activities' : 'Waypoints';

  const moodView = () => {
    if (isMoodLoading) {
      return (
        <Skeleton
          height='340px'
          width='100%'
          sx={{
            transform: 'scale(1, 1)',
          }}
        />
      );
    }
    if (isEnoughDataPresent) {
      return (
        <Grid container display='flex' flexWrap='wrap' justifyContent='space-around'>
          {moodOptions.map((cardData, idx) => (
            <Grid item key={cardData.label} mt={2}>
              <Typography textAlign='center'>{cardData.label}</Typography>
              {isNewVersionEnabled ? (
                <GaugeChartSmallV2
                  color={getGaugeColorV2(cardData.mood as MoodV2, cardData.currentValue)}
                  data={getGaugeDataV2(cardData.currentValue)}
                  label={getMoodValueLabel(cardData.mood as MoodV2, cardData.currentValue)}
                />
              ) : (
                <GaugeChartSmall
                  color={getGaugeColor(cardData.mood as Mood, cardData.currentValue)}
                  data={getGaugeData(cardData.currentValue)}
                  value={cardData.currentValue.toString()}
                />
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: isNewVersionEnabled ? -6 : -4,
                  ml: 0,
                  alignItems: 'center',
                }}
              >
                <Comparison
                  moodDifference={cardData.currentValue - cardData.previousValue}
                  cardData={cardData}
                  isComparisonPossible={isComparisonPossible}
                  isNewVersionEnabled={isNewVersionEnabled}
                />
              </Box>
            </Grid>
          ))}
          {!isNewVersionEnabled && (
            <Typography
              textAlign='center'
              fontWeight='400'
              sx={{
                color: Theme.custom.colors.lightTextSecondary,
                marginTop: 4,
              }}
            >
              {isComparisonPossible ? 'change vs. prior 30 day average' : ''}
            </Typography>
          )}
        </Grid>
      );
    }
    else {
      return (
        <Box
          height='340px'
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography color={Theme.custom.colors.lightTextSecondary} mt={2}>
            {noDataMessage}
          </Typography>
        </Box>
      );
    }
  };

  const filteredMedications = React.useMemo(
    () => medicationsCount.reduce((acc, curr) => acc + curr.count, 0),
    [medicationsCount],
  );

  return (
    <TableRow sx={{ backgroundColor: Theme.custom.colors.white, margin: 0, paddingLeft: 4 }}>
      <TableCell colSpan={7} sx={{ margin: 0, padding: 0, paddingBottom: 2 }}>
        <Box
          borderRadius={4}
          border={`1px solid ${Theme.custom.colors.primaryMain}`}
          marginX={3}
          maxWidth='80%'
          marginTop={3}
          padding={2}
          display='flex'
          flexWrap='wrap'
          position='relative'
          columnGap={6}
          rowGap={1}
        >
          <Typography
            variant='h6'
            position='absolute'
            top={-15}
            left={15}
            px={2}
            sx={{ background: '#FFF' }}
            color={Theme.custom.colors.lightTextPrimary}
          >
            Health Records
          </Typography>
          {medicationsCountQuery.isLoading && (
            <Skeleton
              height='57px'
              width='100%'
              sx={{
                transform: 'scale(1, 1)',
              }}
            />
          )}
          {!medicationsCountQuery.isLoading && filteredMedications === 0 && (
            <Typography variant='body1' color={Theme.custom.colors.lightTextSecondary} width='100%' height='57px' display='flex' alignItems='center'>
              No data currently exists
            </Typography>
          )}
          {!medicationsCountQuery.isLoading
            && medicationsCount.map((m) => (m.count ? (
              <Typography
                key={m.name}
                display='flex'
                alignItems='center'
                variant='body1'
                flex='0 1 auto'
                color={Theme.custom.colors.lightTextSecondary}
              >
                {sentenceCase(m.name)}
                {' '}
                <Typography ml={2} color={Theme.custom.colors.lightTextPrimary}>
                  {m.count}
                </Typography>
              </Typography>
            ) : null))}
        </Box>
        <Grid
          container
          spacing={6}
          sx={{
            margin: 0,
            padding: 0,
            width: '100%',
            ml: -3,
            paddingBottom: 4,
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          <Grid xs={4} item flexShrink={1} display='flex' flexDirection='column' flexWrap='wrap'>
            <Typography variant='h6'>Mood </Typography>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='body2' sx={{ color: Theme.custom.colors.lightTextSecondary }}>
                30-day
                {' '}
                {isNewVersionEnabled ? 'most reported' : 'averages'}
              </Typography>
              {isNewVersionEnabled && isEnoughDataPresent && (
              <Typography
                variant='body2'
                sx={{ color: Theme.custom.colors.lightTextSecondary }}
              >
                {currentMoodData?.moods?.length}
                {' '}
                log
                {currentMoodData?.moods?.length > 1 ? 's' : ''}
              </Typography>
              )}
            </Box>

            <Divider sx={{ margin: '10px 0' }} />
            {moodView()}
          </Grid>

          <Grid xs={4} flexGrow={1} minWidth={350} item>
            <Typography variant='h6'>{activityText}</Typography>
            <Typography variant='body2' sx={{ color: Theme.custom.colors.lightTextSecondary }}>
              30-day averages
            </Typography>
            <Divider sx={{ margin: '10px 0' }} />
            <Box mt={1}>
              <PatientTrackingSummary
                patientId={id}
                isBehavioralDataPresent={isBehavioralDataPresent}
              />
            </Box>
          </Grid>
          <Grid xs={4} flexShrink={1} item>
            <Typography variant='h6'>Profile</Typography>
            <Typography variant='body2' sx={{ color: Theme.custom.colors.lightTextSecondary }}>
              Past 30 days
            </Typography>
            <Divider sx={{ margin: '10px 0' }} />
            <PatientRatingScalesSummary
              personalityProfileData={personalityProfileResults ?? []}
              isRatingScalesDataPresent={isScaleDataPresent}
              isPersonalityProfileDataPresent={isPersonalityProfileDataPresent}
              id={id}
              ratingScalesResults={scaleResults ?? []}
              ratingScales={applicableRatingScales ?? []}
              personalityCategories={personalityProfileCategories ?? []}
              isLoading={isRatingScaleLoading}
              patient={patient}
              isBehavioralDataPresent={isBehavioralDataPresent}
            />
          </Grid>
        </Grid>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: 2 }}>
          <Button
            variant='contained'
            endIcon={<ArrowRightAlt />}
            onClick={navigateToPatientDetailsPage}
          >
            VIEW MORE
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
}

// Memoize the component
const ExpandedRowViewMemo = React.memo(ExpandedRowView);

export default ExpandedRowViewMemo;
