import * as React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import {
  ApproveProviderRequestPayload,
  ListProviderRequestAPIResponse,
  PracticeRequestAction,
  ProviderRequest,
} from 'pages/Authentication/types/CreateProvider';
import { Practice } from 'types/Entitites';
import { Logger } from 'utils/logger';
import { addressSerializar } from 'utils/phoneUtils';
import BorderTableRow from 'components/Common/BorderTableRow';
import { useMutation, useQuery } from 'react-query';
import { providerSignUpUrls } from 'utils/apiUrls';
import { listProviderRequest } from 'pages/Dashboard/services/providersignup.services';
import approveProviderRequest from 'pages/Dashboard/services/approveprovider.services';
import { Box, Typography } from '@mui/material';
import Theme from 'theme';
import ContentLoader from 'components/Common/ContentLoader';

function ProviderPracticeList({ practices }: { practices: Practice[] }) {
  return (
    <Box>
      {practices.map((practice, index) => (
        <Box key={`practice-${practice.id}`} mt={index > 0 ? 1 : 0}>
          <Typography variant='body1'>{practice.name}</Typography>
          <Typography variant='body2' color={Theme.custom.colors.lightTextSecondary}>{addressSerializar(practice.address)}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default function ProviderRequestList() {
  const { http } = useHttp();

  const [toastProps, setToastProps] = React.useState<ToastProps | undefined>();
  const [editRequest, setEditRequest] = React.useState({ request: null, practice: null } as {
    request: ProviderRequest | null;
    practice: Practice | null;
  });
  const [providerConnectionData, setProviderConnectionData] = React.useState<{
    practices: Practice[];
  }>({ practices: [] });

  const {
    data,
    isFetching,
    refetch: refetchProviderList,
  } = useQuery([providerSignUpUrls.listProviderRequest.queryUrl], {
    queryFn: listProviderRequest(http.get),
  });

  const { mutate: approveProvider, reset, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: ({
      providerId,
      payload,
    }: {
      providerId: string;
      payload: ApproveProviderRequestPayload;
    }) => approveProviderRequest(http.post, providerId, { ...payload }),
  });

  const providerRequestListResponse = data as ListProviderRequestAPIResponse;

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isSuccess) {
      setToastProps({ message: 'Approved', open: true, type: ToastType.success });
      reset();
      setProviderConnectionData({ practices: [] });
      refetchProviderList();
    }
    else if (isError) {
      setToastProps({
        message:
          (error as Error)?.message || 'Something went wrong',
        open: true,
        type: ToastType.error,
      });
      setProviderConnectionData({ practices: [] });
      reset();
    }
  }, [refetchProviderList, reset, http.get, isLoading, isSuccess, isError, error]);


  const requests = providerRequestListResponse?.requests ?? [];


  const handleApproveProviderRequest = React.useCallback((practices: Practice[]) => {
    if (!editRequest.request || !editRequest.request.id) {
      Logger.error('Missing request id');
      return;
    }

    const approveProviderRequestPayload: ApproveProviderRequestPayload = {
      id: editRequest.request.id,
      practices: practices.map((practice) => ({
        ...practice,
        action: PracticeRequestAction.PRACTICE_REQUEST_ACTION_CREATE,
        id: practice.id,
      })),
    };

    approveProvider({
      providerId: editRequest.request.id,
      payload: approveProviderRequestPayload,
    });

    setEditRequest({ request: null, practice: null });
  }, [approveProvider, editRequest.request]);

  const updateProviderConnection = React.useCallback((practice: Practice) => {
    const val = providerConnectionData.practices.findIndex((e) => e.id === practice.id);
    const practices = [...providerConnectionData.practices];
    if (val > -1) {
      providerConnectionData.practices.splice(val, 1, practice);
      setProviderConnectionData({
        ...providerConnectionData,
      });
    }
    else {
      setProviderConnectionData({
        ...providerConnectionData,
        practices: [...providerConnectionData.practices, practice],
      });
      practices.push(practice);
    }
    handleApproveProviderRequest(practices);
  }, [providerConnectionData, handleApproveProviderRequest]);


  const onSaveWithoutCredentials = React.useCallback((practice: Practice) => {
    updateProviderConnection({
      id: practice.id,
      name: practice.name,
      address: practice.address,
      action: 1,
      connections: [],
    });
  }, [updateProviderConnection]);

  React.useEffect(() => {
    if (editRequest.practice && editRequest.request) {
      onSaveWithoutCredentials(editRequest.practice);
    }
  }, [editRequest, onSaveWithoutCredentials]);


  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastProps(undefined);
  };

  return (
    <>
      <ContentLoader isError={false} isFetching={isFetching} minHeight={400}>
        <TableContainer>
          <Table aria-label='provider-requests-table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Provider Name</TableCell>
                <TableCell>Provider Email</TableCell>
                <TableCell>Practices</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests?.length > 0 ? (
                requests.map((request) => (
                  <BorderTableRow key={`provider-request-${request.id}`}>
                    <TableCell>
                      {`${request.name.firstName || ''} ${request.name.lastName || ''}`}
                    </TableCell>
                    <TableCell>{request.email}</TableCell>
                    <TableCell>
                      <ProviderPracticeList practices={request.practices} />
                    </TableCell>
                    <TableCell sx={{ paddingX: 1 }}>
                      <Box display='flex' flexDirection='column' alignItems='flex-start'>
                        {request.practices.map((practice) => (
                          <Button
                            key={practice.id}
                            variant='text'
                            onClick={() => {
                              setEditRequest({ request, practice });
                            }}
                          >
                            Approve
                          </Button>
                        ))}
                      </Box>
                    </TableCell>
                  </BorderTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography variant='body1'>No provider requests found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentLoader>
      {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}
    </>
  );
}
