export const setSessionStorageValue = (key: string, newValue: string) => {
  sessionStorage.setItem(key, JSON.stringify(newValue));
};

export const deleteSessionStorageValue = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorageValue = (key: string): string | null => {
  const storedValue = sessionStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

export const getSessionStorageNumber = (key: string): number | null => {
  const storedValue = sessionStorage.getItem(key);
  if (storedValue) {
    try {
      return parseInt(JSON.parse(storedValue) ?? '', 10);
    }
    catch (error) {
      return null;
    }
  }
  return null;
};
