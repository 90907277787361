import React from 'react';
import { useHttp } from 'hooks/use-fetch';
import { AssociateProviderPayload } from 'pages/Dashboard/types/providers.types';
import { useMutation } from 'react-query';
import { resendProviderActivationEmail, updateAssociatedProviders, updateEmailAndResendProviderActivationEmail } from 'pages/Dashboard/services/provider.services';
import AssociateProvidersAction from 'pages/AdminPortal/Actions/AssociateProviders';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dialog } from 'components/Common/Dialog';
import UpdateAndResendActivationEmail from 'pages/AdminPortal/Actions/UpdateAndResendActivationEmail';
import { ToastProps, ToastType } from 'components/Common/Toast';
import { deletePracticeBundle, updatePracticeBundle } from 'pages/AdminPortal/services/practicebundles.services';
import { CategoryType } from 'pages/Dashboard/types/whoiam.types';
import { UpdatedBundle, UpdatePracticeBundlePayload } from 'pages/AdminPortal/types';
import UpdatePracticeBundles from 'pages/AdminPortal/Actions/UpdatePracticeBundles';

type UpdateBundleCallback = React.Dispatch<React.SetStateAction<Record<string, UpdatedBundle>>>;


export enum Action {
  ResendInvite = 'resend_invite',
  ChangeProviderEmail = 'change_provider_email',
  DeletePatient = 'delete_patient',
  ChangePatientNameGender = 'change_patient',
  AddBundleToPractice = 'add_bundle_to_practice',
  UpdateProviderAssociations = 'update_provider_associations',
}

const practiceActionMap = {
  [Action.AddBundleToPractice]: {
    key: Action.AddBundleToPractice,
    title: 'Update practice bundles',
    component: ({
      practiceId,
      setUpdatedPracticeBundle,
    }: {
      practiceId: string;
      setUpdatedPracticeBundle: UpdateBundleCallback;
    }) => (
      <UpdatePracticeBundles
        practiceId={practiceId}
        setUpdatedPracticeBundle={setUpdatedPracticeBundle}
      />
    ),
  },
};


const providersActionMap = {
  [Action.UpdateProviderAssociations]: {
    key: Action.UpdateProviderAssociations,
    title: 'Update provider associations',
    component: ({
      practiceId,
      providerId,
      setProviders,
    }: {
      practiceId: string;
      providerId: string;
      setProviders: ({
        toAssociate,
        toDisassociate,
      }: {
        toAssociate: string[];
        toDisassociate: string[];
      }) => void;
    }) => (
      <AssociateProvidersAction
        practiceId={practiceId}
        providerId={providerId}
        setProviders={setProviders}
      />
    ),
  },
  [Action.ResendInvite]: {
    key: Action.ResendInvite,
    title: 'Resend activation email',
    component: ({
      email,
      setProviderEmail,
    }: {
      email?: string;
      setProviderEmail?: (email: string) => void;
    }) => (
      <UpdateAndResendActivationEmail
        email={email}
        setProviderEmail={setProviderEmail}
        readOnly
      />
    ),
  },
  [Action.ChangeProviderEmail]: {
    key: Action.ChangeProviderEmail,
    title: 'Change and resend activation email',
    component: ({
      email,
      setProviderEmail,
    }: {
      email?: string;
      setProviderEmail?: (email: string) => void;
    }) => (
      <UpdateAndResendActivationEmail
        email={email}
        setProviderEmail={setProviderEmail}
      />
    ),
  },
  // [Action.ChangePatientNameGender]: {
  //   key: Action.ChangePatientNameGender,
  //   title: 'Change patient name / gender',
  //   component: ({
  //     practiceId,
  //     providerId,
  //   }: {
  //     practiceId: string;
  //     providerId: string;
  //   }) => (
  //     <AssociateProvidersAction
  //       practiceId={practiceId}
  //       providerId={providerId}
  //     />
  //   ),
  // },
};

export default function ActionsMenu({
  setToastProps,
  practiceId: selectedPracticeId,
  providerId: selectedProviderId,
  providerEmail,
  practiceName,
  refetchProvidersList,
}: {
  setToastProps: (props: ToastProps | undefined) => void
  practiceId: string;
  providerId?: string;
  providerEmail?: string;
  practiceName?: string;
  refetchProvidersList?: () => void;
}) {
  const { http } = useHttp();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedAction, setSelectedAction] = React.useState<Action>('' as Action);
  const [selectedProviders, setSelectedProviders] = React.useState({
  } as {
    toAssociate: string[];
    toDisassociate: string[];
  });
  const [updatedProviderEmail, setUpdatedProviderEmail] = React.useState('');
  const [updatedPracticeBundle, setUpdatedPracticeBundle] = React.useState(
    {} as Record<string, UpdatedBundle>,
  );

  const { mutate: updateProviderAssociations, isLoading } = useMutation({
    mutationFn: (
      payload: AssociateProviderPayload,
    ) => updateAssociatedProviders(http.post, { ...payload }),
    onSettled: () => {
      onClose();
    },
    onSuccess: () => {
      setToastProps({ message: 'Provider\'s associations updated successfully', open: true, type: ToastType.success });
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e) || 'There was an error updating the provider\'s associations') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
    },
    retry: 0,
  });

  const { mutate: resendActivationEmail, isLoading: isSendingActivationEmail } = useMutation({
    mutationFn: () => resendProviderActivationEmail(http.post, selectedProviderId as string),
    onSettled: () => {
      onClose();
    },
    onSuccess: () => {
      setToastProps({ message: 'Activation email sent successfully', open: true, type: ToastType.success });
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e) || 'There was an error sending the activation email') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
    },
    retry: 0,
  });

  const {
    mutate: updateEmailAndResendActivationEmail,
    isLoading: isUpdatingAndSendingActivationEmail,
  } = useMutation({
    mutationFn: () => updateEmailAndResendProviderActivationEmail(
      http.patch,
        selectedProviderId as string,
        updatedProviderEmail as string,
    ),
    onSettled: () => {
      refetchProvidersList?.();
      onClose();
    },
    onSuccess: () => {
      setToastProps({ message: 'Activation email sent successfully', open: true, type: ToastType.success });
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e) || 'There was an error sending the activation email') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
    },
    retry: 0,
  });

  const {
    mutateAsync: updatePracticeBundleById,
    isLoading: isUpdatingPracticeBundles,
  } = useMutation({
    mutationFn: ({
      payload,
      bundleId,
    }: {
        payload: UpdatePracticeBundlePayload;
        bundleId?: string;
      }) => updatePracticeBundle(
      bundleId ? http.put : http.post,
          selectedPracticeId as string,
          payload as UpdatePracticeBundlePayload,
          bundleId as string,
    ),
    onSettled: () => {
      onClose();
    },
    onSuccess: () => {
      setToastProps({
        message: 'Practice bundles updated successfully',
        open: true,
        type: ToastType.success,
      });
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e)
          || 'There was an error updating the practice bundles') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
    },
    retry: 0,
  });

  const {
    mutateAsync: deletePracticeBundleById,
    isLoading: isDeletingPracticeBundle,
  } = useMutation({
    mutationFn: ({
      bundleId,
    }: {
      bundleId: string;
    }) => deletePracticeBundle(
      http.delete,
        selectedPracticeId as string,
        bundleId as string,
    ),
    onSettled: () => {
      onClose();
    },
    onSuccess: () => {
      setToastProps({
        message: 'Practice bundles updated successfully',
        open: true,
        type: ToastType.success,
      });
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e)
        || 'There was an error deleting the practice bundles') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
    },
    retry: 0,
  });

  // Handle menu open
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleClose = (): void => {
    setAnchorEl(null);
    setToastProps(undefined);
  };

  const handleActionClick = (action: Action) => {
    setSelectedAction(action);
    handleClose();
  };

  const actionsMap = (
    selectedPracticeId && selectedProviderId ? providersActionMap : practiceActionMap
  ) as Record<
    Action,
    {
      key: Action;
      title: string;
      content?: string;
      component?: (props: {
        practiceId: string;
        providerId?: string;
        setProviders?: ({
          toAssociate,
          toDisassociate,
        }: {
          toAssociate: string[];
          toDisassociate: string[];
        }) => void;
        email?: string;
        setProviderEmail?: (email: string) => void;
        setUpdatedPracticeBundle?: UpdateBundleCallback;
      }) => JSX.Element;
    }
  >;

  const onClose = () => {
    setSelectedAction('' as Action);
    setSelectedProviders({ toAssociate: [], toDisassociate: [] });
    setUpdatedProviderEmail('');
    setUpdatedPracticeBundle({});
  };

  const onSave = () => {
    if (selectedAction === Action.UpdateProviderAssociations) {
      if (selectedProviders.toAssociate.length > 0) {
        updateProviderAssociations({
          staff_id: selectedProviderId as string,
          practice_id: selectedPracticeId,
          provider: selectedProviders.toAssociate.map((provider) => ({ id: provider })),
          associate: true,
        });
      }

      if (selectedProviders.toDisassociate.length > 0) {
        updateProviderAssociations({
          staff_id: selectedProviderId as string,
          practice_id: selectedPracticeId,
          provider: selectedProviders.toDisassociate.map((provider) => ({ id: provider })),
          associate: false,
        });
      }
    }
    else if (selectedAction === Action.ResendInvite) {
      resendActivationEmail();
    }
    else if (selectedAction === Action.ChangeProviderEmail) {
      updateEmailAndResendActivationEmail();
    }
    else if (selectedAction === Action.AddBundleToPractice) {
      const {
        bundleId: personalityProfileBundleId,
        payload: personalityProfilePayload,
        deleteAll: deleteAllPersonalityProfile,
      } = updatedPracticeBundle[CategoryType.WIM_CATEGORY_TYPE_PERSONALITY_PROFILE] ?? {};
      if (deleteAllPersonalityProfile) {
        if (personalityProfileBundleId) {
          deletePracticeBundleById({
            bundleId: personalityProfileBundleId,
          });
        }
        else {
          onClose();
          setToastProps({
            message: 'Practice bundle updated successfully',
            open: true,
            type: ToastType.success,
          });
        }
      }
      const hasPersonalityProfileToUpdate = personalityProfilePayload?.bundle_items?.length > 0
      && !deleteAllPersonalityProfile;
      if (hasPersonalityProfileToUpdate) {
        updatePracticeBundleById({
          payload: personalityProfilePayload,
          bundleId: personalityProfileBundleId,
        });
      }
      const {
        bundleId: ratingScaleBundleId,
        payload: ratingScalePayload,
        deleteAll: deleteAllRatingScale,
      } = updatedPracticeBundle[CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE] ?? {};
      if (deleteAllRatingScale) {
        if (ratingScaleBundleId) {
          deletePracticeBundleById({
            bundleId: ratingScaleBundleId,
          });
        }
        else {
          onClose();
          setToastProps({
            message: 'Practice bundle updated successfully',
            open: true,
            type: ToastType.success,
          });
        }
      }
      const hasRatingScalesToUpdate = ratingScalePayload?.bundle_items?.length > 0
      && !deleteAllRatingScale;
      if (hasRatingScalesToUpdate) {
        updatePracticeBundleById({ payload: ratingScalePayload, bundleId: ratingScaleBundleId });
      }
    }
  };


  const actionBtnDisabledMap: Record<Action, boolean> = {
    [Action.ChangeProviderEmail]: !updatedProviderEmail,
    [Action.UpdateProviderAssociations]:
      (selectedProviders?.toAssociate?.length ?? 0)
      + (selectedProviders?.toDisassociate?.length ?? 0) === 0,
    [Action.ResendInvite]: false,
    [Action.DeletePatient]: false,
    [Action.ChangePatientNameGender]: false,
    [Action.AddBundleToPractice]: Object.values(updatedPracticeBundle).length === 0,
  };

  return (
    <>
      <Dialog
        open={!!selectedAction}
        title={`${actionsMap[selectedAction]?.title}${practiceName ? ` (${practiceName})` : ''}`}
        actionBtnLabel='Confirm'
        actionBtnDisabled={actionBtnDisabledMap[selectedAction]}
        actionBtnLoading={
          isLoading
          || isSendingActivationEmail
          || isUpdatingAndSendingActivationEmail
          || isUpdatingPracticeBundles
          || isDeletingPracticeBundle
        }
        size='lg'
        actionBtnHandler={onSave}
        onClose={onClose}
        renderContent={() => (
          <Box minHeight={100}>
            {actionsMap[selectedAction]?.content && (
              <Typography variant='body1'>{actionsMap[selectedAction]?.content}</Typography>
            )}
            {actionsMap[selectedAction]?.component
              && actionsMap[selectedAction]?.component?.({
                practiceId: selectedPracticeId,
                providerId: selectedProviderId,
                setProviders: setSelectedProviders,
                email: providerEmail,
                setProviderEmail: setUpdatedProviderEmail,
                setUpdatedPracticeBundle,
              })}
          </Box>
        )}
      />
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={anchorEl ? 'long-menu' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{ p: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.values(actionsMap).map(({ title, key }) => (
          <MenuItem key={key} onClick={() => handleActionClick(key)}>
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
