import { ListCategoryByTypeResponse, ListPracticeCategoryByTypeResponse, UpdatePracticeBundlePayload } from 'pages/AdminPortal/types';
import { chiefAdminUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';


export const fetchCategoriesByType = (method: HttpType['get'], categoryType: string) => function async(): Promise<ListCategoryByTypeResponse> {
  return method(chiefAdminUrls.listCategories.apiUrls(categoryType).list);
};

export const fetchPracticeCategoriesByType = (method: HttpType['get'], practiceId: string, categoryType: string) => function async(): Promise<ListPracticeCategoryByTypeResponse> {
  return method(chiefAdminUrls.listPracticeCategories.apiUrls(practiceId, categoryType).list);
};

export const updatePracticeBundle = (method: HttpType['put'] | HttpType['post'], practiceId: string, body: UpdatePracticeBundlePayload, bundleId?: string) => method(chiefAdminUrls.updatePracticeBundle.apiUrls(practiceId as string, bundleId as string).update, body);

export const deletePracticeBundle = (method: HttpType['delete'], practiceId: string, bundleId: string) => method(chiefAdminUrls.deletePracticeBundle.apiUrls(practiceId as string, bundleId as string).delete);

export default fetchCategoriesByType;
