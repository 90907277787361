export enum CategoryName {
  WIM_CATEGORY_NAME_QUALITY_OF_LIFE = 'WIM_CATEGORY_NAME_QUALITY_OF_LIFE',
  WIM_CATEGORY_NAME_CORE_VALUES = 'WIM_CATEGORY_NAME_CORE_VALUES',
  WIM_CATEGORY_NAME_LOCUS_OF_CONTROL = 'WIM_CATEGORY_NAME_LOCUS_OF_CONTROL',
  WIM_CATEGORY_NAME_DISC = 'WIM_CATEGORY_NAME_DISC',
  WIM_CATEGORY_NAME_PHQ8 = 'WIM_CATEGORY_NAME_PHQ8',
  WIM_CATEGORY_NAME_GAD7 = 'WIM_CATEGORY_NAME_GAD7',
  WIM_CATEGORY_NAME_WHO5 = 'WIM_CATEGORY_NAME_WHO5',
}

export enum CategoryType {
  'WIM_CATEGORY_TYPE_RATING_SCALE' = 'WIM_CATEGORY_TYPE_RATING_SCALE',
  'WIM_CATEGORY_TYPE_PERSONALITY_PROFILE' = 'WIM_CATEGORY_TYPE_PERSONALITY_PROFILE'
}

export interface Category {
  id: string;
  name: CategoryName;
  description: string;
  type: CategoryType;
  abbreviation: string;
}

export interface CategoriesResponse {
  categories: Category[]
}


export interface Scale extends CategoryResult {
  categoryId: string;
  scale: string;
}

export interface CategoryResult {
  attemptedAt: Date;
  description: string;
  metaInfo: string;
  score: number;
  totalScore: number;
  attemptId: string;
  reverseColorCoding: boolean;
}

export interface CategoryResultsResponse {
  results: CategoryResult[]
}

export type DISCKeys = 'D' | 'I' | 'S' | 'C'

export type DISCColors = {
  [key in DISCKeys]: string;
}

export type DISCIdentifyingTerms = {
  [key in DISCKeys]: string;
}

export type PatientPersonalityTestResultTextProps = {
  result?: CategoryResult;
  isSummary?: boolean;
}
