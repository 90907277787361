export const generateTicks = (minDate: Date, maxDate: Date): number[] => {
  const ticks: number[] = [];

  // Calculate the total years and months between the dates
  const startDate = new Date(minDate);
  const endDate = new Date(maxDate);

  const totalYears = endDate.getFullYear() - startDate.getFullYear();
  const totalMonths = totalYears * 12 + (endDate.getMonth() - startDate.getMonth());

  const intervals = [1, 2, 3, 4, 6, 12, 24, 60]; // Possible intervals
  const tickInterval = intervals.find((interval) => totalMonths / interval <= 12) || 60;

  // Start from the maxDate
  const currentDate = new Date(maxDate);

  // Add an extra interval of 12 months if the tickInterval is 12, 24, or 60
  if ([12, 24, 60].includes(tickInterval)) {
    currentDate.setMonth(currentDate.getMonth() + 12);
    ticks.push(currentDate.getTime());
  }
  else {
    currentDate.setMonth(currentDate.getMonth() + 1);
    ticks.push(currentDate.getTime());
  }

  // Generate ticks backward using the calculated tickInterval
  while (currentDate >= minDate) {
    ticks.push(currentDate.getTime());
    currentDate.setMonth(currentDate.getMonth() - tickInterval);
  }

  // If the start date is not included, add it explicitly
  if (!ticks.includes(startDate.getTime())) {
    ticks.push(startDate.getTime());
  }

  const uniqueTicks = ticks.map((tick) => {
    const date = new Date(tick);
    if (tickInterval >= 12) {
      date.setMonth(0); // Set to January
      date.setDate(1); // Set to 1st
    }
    else {
      const quarter = Math.floor(date.getMonth() / tickInterval) * tickInterval;
      date.setMonth(quarter); // Set to start of the quarter
      date.setDate(1); // Set to 1st
    }
    return date.getTime();
  });

  const uniqueTicksSet = Array.from(new Set(uniqueTicks));

  // Sort and return unique ticks
  const sortedTicks = uniqueTicksSet.sort((a, b) => a - b);

  const lastDate = new Date(sortedTicks[sortedTicks.length - 1]);
  if (tickInterval >= 12) {
    lastDate.setMonth(11); // Set to December
    lastDate.setDate(0);
  }
  sortedTicks[sortedTicks.length - 1] = lastDate.getTime();

  return sortedTicks;
};

export const formatTickLabel = (unixTime: number, maxDate: Date, minDate: Date) => {
  const date = new Date(unixTime);
  const yearDiff = maxDate.getFullYear() - minDate.getFullYear();

  if (yearDiff < 1) {
    // Display month and year for ranges less than 1 year
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
  }
  else if (yearDiff >= 1 && yearDiff < 6) {
    // Display month and year for ranges between 1 and 6 years
    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
  }
  else {
    // Display only the year for ranges greater than 6 years
    return `${date.getFullYear()}`;
  }
};

export const minDistanceCalculation = (totalYears: number): number => {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;

  // Define intervals as an array of [maxYears, durationInDays]
  const intervals: [number, number][] = [
    [1, 30], // 1 month in days
    [2, 60], // 2 months in days
    [3, 90], // 3 months in days
    [4, 120], // 4 months in days
    [6, 180], // 6 months in days
    [12, 365], // 1 year in days
    [24, 365 * 2], // 2 years in days
  ];

  // Find the first matching interval or default to 5 years in days
  const match = intervals.find(([maxYears]) => totalYears <= maxYears);
  const days = match ? match[1] : 365 * 5; // Default to 5 years in days

  return days * millisecondsPerDay;
};


export default generateTicks;
