import { Box, Button, Grid, Typography } from '@mui/material';
import { Dialog } from 'components/Common/Dialog';
import Input from 'components/Common/Input';
import PhoneNumberInput from 'components/Common/PhoneNumberInput';
import { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import ConfirmPopup from 'pages/AdminPortal/Actions/ConfirmPopup';
import deletePatient from 'pages/AdminPortal/services/deletepatient.services';
import { DeletePatientForm, DeletePatientPayload } from 'pages/AdminPortal/types';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { phoneNumberStringToGooglePhoneNumber } from 'utils/phoneUtils';
import { isValidEmail, isValidPhoneNumber } from 'utils/utilMethods';


interface TProps {
  setToastProps: (props: ToastProps) => void;
}

export default function DeletePatient({
  setToastProps,
}: TProps) {
  const { http } = useHttp();

  const formMethods = useForm<DeletePatientForm>({
    defaultValues: {
      email: '',
      mobile: '',
    },
  });

  const [showDeletePatientModal, setShowDeletePatientModal] = React.useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = React.useState(false);

  const emailValue = formMethods.watch('email')?.trim() ?? '';
  const phoneValue = formMethods.watch('mobile')?.trim() ?? '';

  const validateMobile = useCallback(
    (val: string) => {
      const isEmpty = !val.trim().length;
      if (isEmpty && emailValue.length === 0) {
        return 'Please enter mobile number or email';
      }
      else if (!isEmpty && !isValidPhoneNumber(val)) {
        return 'Invalid phone number';
      }

      return true;
    },
    [emailValue.length],
  );

  const validateEmail = useCallback(
    (val: string) => {
      const isEmpty = !val.trim().length;
      if (isEmpty && phoneValue.length === 0) {
        return 'Please enter email or mobile number';
      }
      else if (!isEmpty && !isValidEmail(val)) {
        return 'Invalid email';
      }

      return true;
    },
    [phoneValue.length],
  );

  const {
    mutateAsync: deleteProviderPatient,
    isLoading: isDeleteProviderPatient,
  } = useMutation({
    mutationFn: ({
      payload,
    }: {
        payload: DeletePatientPayload;
      }) => deletePatient(
      http.post,
      payload,
    ),
    onSettled: () => {
      onClose();
    },
    onSuccess: () => {
      setToastProps({
        message: 'Patient deleted successfully',
        open: true,
        type: ToastType.success,
      });
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e)
          || 'There was an error deleting the patient') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
    },
    retry: 0,
  });

  const onSave = () => {
    formMethods.handleSubmit(() => {
      setIsConfirmPopupOpen(true);
    })();
  };

  const onClose = () => {
    formMethods.reset();
    setShowDeletePatientModal(false);
  };

  const onConfirm = () => {
    setIsConfirmPopupOpen(false);
    deleteProviderPatient({
      payload: {
        email: emailValue || undefined,
        phone: phoneValue
          ? (phoneNumberStringToGooglePhoneNumber(phoneValue))
          : undefined,
      },
    });
  };


  const handleDeletePatientClick = () => {
    setShowDeletePatientModal(true);
  };

  return (
    <>
      <Button variant='contained' onClick={handleDeletePatientClick} size='medium' sx={{ mr: 2 }}>
        Delete patient
      </Button>
      <ConfirmPopup
        open={isConfirmPopupOpen}
        onClose={() => setIsConfirmPopupOpen(false)}
        onConfirm={onConfirm}
        title='Are you sure? You want to delete the patient with the given details?'
      />
      <Dialog
        open={showDeletePatientModal}
        title='Delete patient'
        actionBtnLabel='Delete'
        actionBtnDisabled={!emailValue && !phoneValue}
        actionBtnLoading={isDeleteProviderPatient}
        size='lg'
        actionBtnHandler={onSave}
        onClose={onClose}
        renderContent={() => (
          <Box>
            <Typography variant='body1' mb={2}>
              Please enter the patient phone number or email address
            </Typography>
            <FormProvider {...formMethods}>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <PhoneNumberInput
                      name='mobile'
                      control={formMethods.control}
                      label='Mobile phone number'
                      placeholder='Mobile phone number'
                      rules={{
                        validate: validateMobile,
                      }}
                      isMasked
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color='textSecondary'>
                      OR
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={formMethods.control}
                      name='email'
                      label='Email'
                      textFieldProps={{
                        type: 'email',
                        placeholder: 'Email',
                      }}
                      rules={{
                        validate: validateEmail,
                      }}
                      isMasked
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        )}
      />
    </>
  );
}
